import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    style: _normalizeStyle({'font-size': _ctx.size})
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(_ctx.name)
    }, null, 2)
  ], 4))
}