import {del, get, getOrNull, post, putMultipart} from '@/services/http.service'
import {
    IndependantCreateResource,
    IndependantPermissions,
    IndependantReadResource,
    IndependantUpdateResource
} from '@/model/independant'
import {getEntiteLogoById} from '@/services/entite.service'
import {LabelData} from '@/model/common'

export function findAllLabelIndependants(): Promise<LabelData[]> {
    return get.value('/api/independant')
}

export async function getIndependantById(id: string): Promise<IndependantReadResource|null> {
    const [ resource, logo ] = await Promise.all([getOrNull.value<IndependantReadResource>(`/api/independant/${id}`), getEntiteLogoById(id)])
    if (resource === null) return null
    return {
        ...resource,
        entite: {
            ...resource.entite,
            logo: logo
        }
    }

}

export function createIndependantByUtilisateurId(utilisateurId: string, independant: IndependantCreateResource): Promise<void> {
    return post.value(`/api/independant/${utilisateurId}`, independant)
}

export function updateIndependantById(id: string, independant: IndependantUpdateResource, logo: File | null): Promise<void> {
    const formData = new FormData()
    formData.append("resource", JSON.stringify(independant))
    if (logo != null) {
        formData.append("logo", logo)
    }
    return putMultipart.value(`/api/independant/${id}`,formData)
}

export function deleteIndependantById(id: string): Promise<void> {
    return del.value(`/api/independant/${id}`)
}

export async function getIndependantPermissionsById(id: string): Promise<IndependantPermissions> {
    return get.value(`/api/independant/${id}/permissions`)
}
