
import {computed, defineComponent} from 'vue';
export default defineComponent({
  name: 'Row',
  props: {
    justifyContent: {
      type: String,
      default: ''
    },
    alignItems: {
      type: String,
      default: 'baseline'
    },
    rowGap: {
      type: String,
      default: '0'
    },
    columnGap: {
      type: String,
      default: '1rem'
    },
    wrap: {
      type: String,
      default: 'nowrap'
    }
  },
  setup(props) {
    return {
      style: computed(() => ({
        'align-items': props.alignItems,
        'justify-content': props.justifyContent,
        'column-gap': props.columnGap,
        'row-gap': props.rowGap,
        'flex-wrap': props.wrap
      }))
    }
  }
})
