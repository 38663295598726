import {Adresse, LabelData, PaginationCriteria, Sort} from '@/model/common'
import {OptionTva} from '@/model/prestation'
import {Contact} from '@/model/contact'
import {LieuPriseEnChargeType} from '@/model/dossier/patient'

export type ClientStatut = 'ATTENTE' | 'EN_COURS' | 'TERMINE'

export const clientStatutLabelMap: Record<ClientStatut, string> = {
    ATTENTE: 'Attente',
    EN_COURS: 'En cours',
    TERMINE: 'Terminé'
}

export interface GerantAssocieTableClient {
    id: string,
    nomRaisonSociale: string,
    prenom: string | null,
    codePostal: string,
    ville: string,
    statut: string,
    utilisateurs: LabelData[],
    tarifDefaut: number | null
}

export interface CollaborateurTableClient {
    id: string,
    nomRaisonSociale: string,
    prenom: string | null,
    codePostal: string,
    ville: string,
    statut: string
}

export interface VacataireTableClient {
    id: string,
    nomRaisonSociale: string,
    prenom: string | null,
    codePostal: string,
    ville: string,
    statut: string
}

export interface IndependantTableClient {
    id: string,
    nomRaisonSociale: string,
    prenom: string | null,
    codePostal: string,
    ville: string,
    statut: string
}

export interface ClientWriteResource{
    statut: ClientStatut,
    nomRaisonSociale: string,
    prenom: string | null,
    reference: string | null,
    demande: string | null,
    email: string | null,
    contacts: Contact[],
    telephones: ClientTelephone[],
    fichiers: ClientFichierCreateResource[],
    lieuPriseEnCharges: ClientLieuPriseEnCharge[],
    tarifDefaut: number | null,
    siret: string | null,
    adresse: Adresse,
    codeApe: string | null
}

export interface ClientReadResource{
    statut: ClientStatut,
    nomRaisonSociale: string,
    prenom: string | null,
    reference: string | null,
    demande: string | null,
    email: string | null,
    contacts: Contact[],
    telephones: ClientTelephone[],
    fichiers: ClientFichierReadResource[],
    lieuPriseEnCharges: ClientLieuPriseEnCharge[],
    tarifDefaut: number | null,
    siret: string | null,
    adresse: Adresse,
    codeApe: string | null
}

export interface ClientFichierReadResource {
    id: string,
    type: ClientFichierType,
    nom: string,
}

export interface ClientReadData {
    statut: ClientStatut,
    nomRaisonSociale: string,
    prenom: string | null,
    reference: string | null,
    demande: string | null,
    email: string | null,
    contacts: Contact[],
    telephones: ClientTelephone[],
    fichiers: ClientFichierReadData[],
    lieuPriseEnCharges: ClientLieuPriseEnCharge[],
    tarifDefaut: number | null,
    siret: string | null,
    adresse: Adresse,
    codeApe: string | null
}

export interface ClientWriteData{
    statut: ClientStatut,
    nomRaisonSociale: string,
    prenom: string | null,
    reference: string | null,
    demande: string | null,
    email: string | null,
    contacts: Contact[],
    telephones: ClientTelephone[],
    fichiers: ClientFichierCreateData[],
    lieuPriseEnCharges: ClientLieuPriseEnCharge[],
    tarifDefaut: number | null,
    siret: string | null,
    adresse: Adresse,
    codeApe: string | null
}

export interface ClientTelephone{
    nom: string,
    telephone: string
}

type ClientFichierType = 'CONTRAT' | 'DEVIS' | 'DIVERS'
export const clientFichierTypeLabelMap: Record<ClientFichierType, string> = {
    CONTRAT: 'Contrat',
    DEVIS: 'Devis',
    DIVERS: 'Divers'
}

export interface ClientFichierCreateResource{
    type: ClientFichierType,
    nom: string
}

export interface ClientFichierCreateData{
    type: ClientFichierType,
    nom: string,
    file: File
}

export interface ClientFichierReadData{
    file: File,
    type: ClientFichierType,
    nom: string
}


export interface Client {
    id: string | null;
    entiteId: string;
    identite: ClientIdentite;
    tva: ClientTva;
}

export interface ClientIdentite {
    id: string | null;
    denomination: string;
    adresse: Adresse;
}

export interface ClientTva {
    id: string | null;
    optionTvaDefaut: OptionTva | null
}

export interface ClientCriteria {
    pagination: PaginationCriteria,
    filters: ClientCriteriaFilters,
    sort: Sort | null
}

export interface GerantAssocieClientCriteria {
    pagination: PaginationCriteria,
    filters: GerantAssocieClientCriteriaFilters,
    sort: Sort | null
}

export interface GerantAssocieClientCriteriaFilters {
    nomRaisonSociale: string | null
    prenom: string | null,
    codePostal: string | null,
    statut: ClientStatut | null
    utilisateurIdOrNone: string | null
}

export interface ClientCriteriaFilters {
    nomRaisonSociale: string | null
    prenom: string | null,
    codePostal: string | null,
    statut: ClientStatut | null
}

export interface ClientLieuPriseEnCharge {
    type: LieuPriseEnChargeType,
    nom: string | null,
    adresse: Adresse | null,
    contact: Contact | null,
    infos: string | null
}

export interface ClientDevisReadData {
    id: string,
    nom: string,
    file: File
}

export interface ClientDevisReadResource {
    id: string,
    nom: string
}
