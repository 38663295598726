import {get, getFileOrNull, put, putMultipart} from '@/services/http.service'
import {EntiteFactureCountData, EntiteReadData, EntiteUpdateResource} from '@/model/entite'
import {FactureLibreEntite} from '@/model/facture'


export function getFactureLibreEntiteById(entiteId: string): Promise<FactureLibreEntite> {
    return get.value(`/api/entite/${entiteId}`, {}, {'Representation': 'Facture-Libre'})
}

export function getEntiteLogoById(id: string): Promise<File|null> {
    return getFileOrNull.value(`/api/entite/${id}/logo`)
}

export async function getEntiteById(id: string): Promise<EntiteReadData> {
    const [ resource, logo ] = await Promise.all([get.value<EntiteReadData>(`/api/entite/${id}`), getEntiteLogoById(id)])
    return {
        ...resource,
        logo: logo
    }
}

export function updateEntiteById(id: string, resource: EntiteUpdateResource): Promise<void> {
    const { logo, ...entite } = resource
    const formData = new FormData()
    formData.append("resource", JSON.stringify(entite))
    if(logo != null) {
        formData.append("logo", logo)
    }
    return putMultipart.value(`/api/entite/${id}`,formData)
}

export function updateEntiteSiretById(id: string, siret: string|null): Promise<void> {
    return put.value(`/api/entite/${id}/siret`, { siret })
}

export function countFactureAndAvoirFactureByNumeroNullAndEntiteId(entiteId: string): Promise<EntiteFactureCountData> {
    return get.value(`/api/entite/${entiteId}/_countFactureAvoirFacture`)
}