
import { Pagination } from '@/model/common'
import {computed, defineComponent, PropType} from 'vue';
import Label from "@/components/common/label/Label.vue";
export default defineComponent({
  name: 'ResultsCount',
  components: {
    Label
  },
  props: {
    pageNumber: {
      type: Number,
      required: true
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true
    }
  },
  setup(props) {
    const from = computed(() => props.pagination.size * (props.pageNumber - 1) + 1)
    const to = computed(() => {
      const to = props.pageNumber * props.pagination.size
      return props.pagination.total < to ? props.pagination.total : to
    })
    return {
      from,
      to
    }
  }
})
