import {RouteRecordRaw} from 'vue-router'
import SuperAdminCabinetList from "@/views/admin/cabinet/list/SuperAdminCabinetList.vue";
import CabinetParametresUpdate from "@/views/admin/cabinet/details/CabinetParametresUpdate.vue";

const routes = [
  {
    path: '/cabinets',
    name: 'Cabinets',
    component: SuperAdminCabinetList
  }, {
    path: '/cabinet',
    name: 'Cabinet',
    component: CabinetParametresUpdate
  }
] as Array<RouteRecordRaw>;

export default routes;
