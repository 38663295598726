

import {defineComponent} from "vue";

export default defineComponent({
  name: 'IconButton',
  props: {
    name: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const onClick = (event: Event) => {
      event.stopImmediatePropagation();
      if (!props.disabled) {
        ctx.emit('click');
      }
    }
    return {
      onClick
    }
  }
})
