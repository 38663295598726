import {Adresse, LabelData, PaginationCriteria, Sort} from '@/model/common';
import {Individu} from "@/model/individu";
import {Contact} from "@/model/contact";

export type PatientStatut = 'BILAN_A_VALIDER' | 'BILAN_A_PROGRAMMER' | 'BILAN_EN_COURS' | 'ATTENTE_DENOUEMENT_BILAN' | 'ATTENTE_ATTRIBUTION' | 'ATTENTE_SUIVI' | 'EN_COURS' | 'TERMINE'
export const patientStatutLabelMap: Record<PatientStatut, string> = {
    BILAN_A_VALIDER: 'Bilan à valider',
    BILAN_A_PROGRAMMER: 'Bilan à programmer',
    BILAN_EN_COURS: 'Bilan en cours',
    ATTENTE_DENOUEMENT_BILAN: 'Attente de dénouement de bilan',
    ATTENTE_ATTRIBUTION: 'Attente d\'attribution',
    ATTENTE_SUIVI: 'Attente de suivi',
    EN_COURS: 'En cours',
    TERMINE: 'Terminé'
}

export type PatientStatutTermine = 'BILAN' | 'FIN_SUIVI' | 'SANS_SUITE' | 'INCIDENT'
export const patientStatutTermineLabelMap: Record<PatientStatutTermine, string> = {
    BILAN: 'Sans nécessité de soin suite au bilan',
    FIN_SUIVI: 'Fin de suivi',
    SANS_SUITE: 'Sans suite',
    INCIDENT: 'Incident'
}

export interface GerantAssocieTablePatient {
    id: string,
    nom: string,
    prenom: string | null,
    numeroDossier: string,
    codePostal: string,
    ville: string,
    etat: string | null,
    statut: PatientStatut,
    statutTermine: PatientStatutTermine,
    statutTermineMotif: string | null,
    partageUtilisateurs: LabelData[],
    remplacantUtilisateurs: LabelData[]
}

export interface CollaborateurTablePatient {
    id: string,
    nom: string,
    prenom: string | null,
    numeroDossier: string,
    codePostal: string,
    ville: string,
    etat: string | null,
    statut: PatientStatut,
    statutTermine: PatientStatutTermine,
    statutTermineMotif: string | null,
    gestion: boolean,
    attribue: boolean,
    remplace: string | null,
}

export interface VacataireTablePatient {
    id: string,
    nom: string,
    prenom: string | null,
    numeroDossier: string,
    codePostal: string,
    ville: string,
    etat: string | null,
    statut: PatientStatut,
    statutTermine: PatientStatutTermine,
    statutTermineMotif: string | null,
    gestion: boolean,
    attribue: boolean,
    remplace: string | null,
}

export interface IndependantTablePatient {
    id: string,
    nom: string,
    prenom: string | null,
    numeroDossier: string,
    codePostal: string,
    ville: string,
    etat: string | null,
    statut: PatientStatut,
    statutTermine: PatientStatutTermine,
    statutTermineMotif: string | null
}

export interface PatientWriteData {
    individu: Individu,
    etat: string | null
    dateNaissance: string | null,
    datePremierContact: string | null,
    email: string | null,
    adresse: Adresse | null,
    telephones: PatientTelephone[],
    facturation: PatientFacturation,
    lieuPriseEnCharges: PatientLieuPriseEnCharge[],
    dossierMedical: PatientDossierMedicalWriteData,
}

export interface PatientReadData {
    individu: Individu,
    etat: string | null
    statut: PatientStatut,
    statutTermine: PatientStatutTermine | null,
    dernierIncident: string | null,
    dateNaissance: string | null,
    datePremierContact: string | null,
    email: string | null,
    adresse: Adresse | null,
    telephones: PatientTelephone[],
    facturation: PatientFacturation,
    lieuPriseEnCharges: PatientLieuPriseEnCharge[],
    dossierMedical: PatientDossierMedicalReadData,
}

export interface PatientDossierMedicalReadData {
    numeroSecuriteSociale: string | null,
    medecinReferentContact: Contact | null,
    diagnostic: string | null,
    difficultes: string | null,
    fichiers: PatientDossierMedicalFichierReadData[]
}

export interface PatientWriteResource {
    individu: Individu,
    etat: string | null
    dateNaissance: string | null,
    datePremierContact: string | null,
    email: string | null,
    adresse: Adresse | null,
    telephones: PatientTelephone[],
    facturation: PatientFacturation,
    lieuPriseEnCharges: PatientLieuPriseEnCharge[],
    dossierMedical: PatientDossierMedicalWriteResource,
}

export interface PatientReadResource {
    individu: Individu,
    etat: string | null
    statut: PatientStatut,
    statutTermine: PatientStatutTermine,
    dernierIncident: string | null,
    dateNaissance: string | null,
    datePremierContact: string | null,
    email: string | null,
    adresse: Adresse | null,
    telephones: PatientTelephone[],
    facturation: PatientFacturation,
    lieuPriseEnCharges: PatientLieuPriseEnCharge[],
    dossierMedical: PatientDossierMedicalReadResource,
}

export interface PatientDossierMedicalWriteData {
    numeroSecuriteSociale: string | null,
    medecinReferentContact: Contact | null,
    diagnostic: string | null,
    difficultes: string | null,
    fichiers: PatientDossierMedicalFichiersWriteData
}

export interface PatientDossierMedicalFichiersWriteData {
    toCreate: PatientDossierMedicalFichierWriteData[],
    toUpdate: PatientDossierMedicalFichierWriteData[],
    toDelete: string[]
}

export interface PatientDossierMedicalWriteResource {
    numeroSecuriteSociale: string | null,
    medecinReferentContact: Contact | null,
    diagnostic: string | null,
    difficultes: string | null,
    fichiers: PatientDossierMedicalFichiersWriteResource
}

export interface PatientDossierMedicalReadResource {
    numeroSecuriteSociale: string | null,
    medecinReferentContact: Contact | null,
    diagnostic: string | null,
    difficultes: string | null,
    fichiers: PatientDossierMedicalFichierReadResource[]
}

export type PatientDossierMedicalFichierType =
    'BILAN_ERGOTHERAPIE_INITIAL' |
    'COMPTE_RENDU_ERGOTHERAPIE_SUIVI' |
    'COMPTE_RENDU_ERGOTHERAPIE_FIN_PRISE_EN_CHARGE' |
    'AUTRE_BILAN_ERGOTHERAPIE' |
    'COMPTE_RENDU_VISITE_DOMICILE' |
    'BILAN_COMPTE_RENDU_ORTHOPHONIE' |
    'BILAN_COMPTE_RENDU_PSYCHOMOTRICITE' |
    'BILAN_COMPTE_RENDU_PSYCHOLOGIE' |
    'BILAN_COMPTE_RENDU_NEUROPSYCHOLOGIE' |
    'BILAN_COMPTE_RENDU_MEDICAL' |
    'BILAN_COMPTE_RENDU_SCOLAIRE' |
    'ORDONNANCE' |
    'CERTIFICAT_MEDICAL' |
    'AUTRE_DOCUMENT'

export const patientDossierMedicalFichierTypeLabelMap: Record<PatientDossierMedicalFichierType, string> = {
    BILAN_ERGOTHERAPIE_INITIAL: 'Bilan d’Ergothérapie initial',
    COMPTE_RENDU_ERGOTHERAPIE_SUIVI: 'Compte-rendu d’Ergothérapie de suivi',
    COMPTE_RENDU_ERGOTHERAPIE_FIN_PRISE_EN_CHARGE: 'Compte-rendu d’Ergothérapie de fin de prise en charge',
    AUTRE_BILAN_ERGOTHERAPIE: 'Autre bilan d’Ergothérapie',
    COMPTE_RENDU_VISITE_DOMICILE: 'Compte-rendu de visite à domicile',
    BILAN_COMPTE_RENDU_ORTHOPHONIE: 'Bilan/compte-rendu d’Orthophonie',
    BILAN_COMPTE_RENDU_PSYCHOMOTRICITE: 'Bilan/compte-rendu de psychomotricité',
    BILAN_COMPTE_RENDU_PSYCHOLOGIE: 'Bilan/compte-rendu de psychologie',
    BILAN_COMPTE_RENDU_NEUROPSYCHOLOGIE: 'Bilan/compte-rendu de neuropsychologie',
    BILAN_COMPTE_RENDU_MEDICAL: 'Bilan/compte-rendu médical',
    BILAN_COMPTE_RENDU_SCOLAIRE: 'Bilan/compte-rendu scolaire',
    ORDONNANCE: 'Ordonnance',
    CERTIFICAT_MEDICAL: 'Certificat médical',
    AUTRE_DOCUMENT: 'Autre document'
}

export interface PatientDossierMedicalFichierWriteData {
    id: string | null,
    type: PatientDossierMedicalFichierType,
    nom: string,
    file: File
}

export interface PatientDossierMedicalFichierReadData {
    id: string | null,
    type: PatientDossierMedicalFichierType,
    nom: string,
    file: File
}


export interface PatientDossierMedicalFichiersWriteResource {
    toCreate: PatientDossierMedicalFichierWriteResource[],
    toUpdate: PatientDossierMedicalFichierWriteResource[],
    toDelete: string[]
}

export interface PatientDossierMedicalFichierWriteResource {
    id: string | null,
    type: PatientDossierMedicalFichierType,
    nom: string,
}

export interface PatientDossierMedicalFichierReadResource {
    id: string,
    type: PatientDossierMedicalFichierType,
    nom: string,
}

export interface NomTelephoneCreateData {
    nom: string,
    telephone: string
}

export type PatientTelephone = NomTelephoneCreateData

export type LieuPriseEnChargeType = 'DOMICILE' | 'CABINET' | 'TELECONSULTATION' | 'ECOLE' | 'TRAVAIL' | 'CENTRE_SOIN_REEDUCATION' | 'ETABLISSEMENT_SANTE'
export const lieuPriseEnChargeTypeLabelMap: Record<LieuPriseEnChargeType, string> = {
    CABINET: 'Cabinet',
    CENTRE_SOIN_REEDUCATION: 'Centre de soin ou de rééducation',
    ETABLISSEMENT_SANTE: 'Établissement de santé',
    TRAVAIL: 'Travail',
    ECOLE: 'École',
    TELECONSULTATION: 'Téléconsultation',
    DOMICILE: 'Domicile'
}

export interface PatientLieuPriseEnCharge {
    type: LieuPriseEnChargeType,
    nom: string | null,
    adresse: Adresse | null,
    contact: Contact | null,
    infos: string | null
}

export interface PatientFacturation {
    pourcentage: number,
    entiteFacturables: PatientFacturationEntiteFacturable[]
}

export interface PatientFacturationEntiteFacturable {
    pourcentage: number,
    denomination: string,
    adresse: Adresse,
    email: string | null,
    telephone: string | null
}

export interface PatientDevisReadData {
    id: string,
    nom: string,
    file: File
}

export interface PatientDevisReadResource {
    id: string,
    nom: string
}

export interface PatientCriteria {
    pagination: PaginationCriteria,
    filters: PatientCriteriaFilters,
    sort: Sort | null
}

export interface GerantAssociePatientCriteria {
    pagination: PaginationCriteria,
    filters: GerantAssociePatientCriteriaFilters,
    sort: Sort | null
}

export interface GerantAssociePatientCriteriaFilters {
    nom: string | null
    prenom: string | null,
    codePostal: string | null,
    statut: PatientStatut | null
    utilisateurIdOrNone: string | null
}

export interface PatientCriteriaFilters {
    nom: string | null
    prenom: string | null,
    codePostal: string | null,
    statut: PatientStatut | null
}

export interface PatientRemplacementCriteria {
    pagination: PaginationCriteria,
    filters: PatientRemplacementCriteriaFilters,
    sort: Sort | null
}

export interface PatientRemplacementCriteriaFilters {
    nom: string | null
    prenom: string | null
}

export interface PatientStatutUpdateData {
    statut: PatientStatut,
    statutTermine: PatientStatutTermine | null,
    statutTermineMotif: string | null
}

export interface PatientStatutUpdateResource {
    statut: PatientStatut,
    statutTermine: PatientStatutTermine | null,
}

export interface PatientStatutTermineUpdateResource {
    statutTermine: PatientStatutTermine,
    statutTermineMotif: string | null
}

export interface PatientCompteRenduData {
    necessiteSoins: boolean,
    suivi: boolean,
    compteRendu: File
}

export interface PatientExistsData {
    nom: string,
    prenom: string | null,
    dateNaissance: string
}

export interface PatientExistsExceptIdData {
    id: string,
    nom: string,
    prenom: string | null,
    dateNaissance: string
}
