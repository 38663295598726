

import {defineComponent} from "vue";

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String,
    },
    size: {
      type: String,
      default: '1rem'
    }
  }
})
