import {createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import Admin from "@/views/admin/Admin.vue";
import Accueil from "@/views/admin/Accueil.vue";
import NotAllowed from "@/views/admin/NotAllowed.vue";
import factureRoutes from "@/router/facture/facture-routes";
import avoirFactureRoutes from "@/router/avoir-facture-routes";
import utilisateurRoutes from "@/router/utilisateur-routes";
import cabinetRoutes from "@/router/cabinet-routes";
import evenementRoutes from "@/router/evenement-routes";
import Connexion from "@/views/public/Connexion.vue";
import {isAuthenticated} from "@/services/authentication.service";
import UtilisateurPasswordReset from "@/views/public/UtilisateurPasswordReset.vue";
import {isUtilisateurPasswordResetTokenValid} from "@/services/utilisateur.service";
import prestationRoutes from "@/router/prestation-routes";
import patientRoutes from "@/router/patient-routes";
import clientRoutes from "@/router/client-routes";
import parametresRoutes from "@/router/parametres-routes";
import CabinetBilanReservation from "@/views/public/CabinetBilanReservation.vue"
import IndependantBilanReservation from "@/views/public/IndependantBilanReservation.vue"
import AnnulationBilan from "@/views/public/PatientBilanAnnulation.vue"

import agendaRoutes from "@/router/agenda-routes";
import statistiquesRoutes from '@/router/statistiques-routes'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/connexion',
        name: 'Connexion',
        component: Connexion
    },
    {
        path: '/reinitialisation-mot-de-passe/:token',
        name: 'Utilisateur Password Reset',
        component: UtilisateurPasswordReset,
        async beforeEnter(to, from, next) {
            to.meta.tokenValid = await isUtilisateurPasswordResetTokenValid(to.params.token as string)
            next();
        },
        props: (route: RouteLocationNormalized) => ({
            token: route.params.token,
            tokenValid: route.meta.tokenValid
        })
    }, {
        path: '/reservation/cabinet/:id',
        name: 'Calendrier cabinet',
        component: CabinetBilanReservation,
        props: (route: RouteLocationNormalized) => ({
            id: route.params.id as string
        })
    }, {
        path: '/reservation/:id',
        name: 'Calendrier independant',
        component: IndependantBilanReservation,
        props: (route: RouteLocationNormalized) => ({
            id: route.params.id as string
        })
    }, {
        path: '/annulation-rendez-vous/:id',
        name: 'Annulation bilan',
        component: AnnulationBilan,
        props: (route: RouteLocationNormalized) => ({
            id: route.params.id as string
        })
    }, {
        path: '/',
        name: 'Admin',
        component: Admin,
        async beforeEnter(to, from, next) {
            const authenticated = await isAuthenticated()
            if (authenticated) {
                next();
            } else {
                next('/connexion')
            }
        },
        children: [
            {
                path: '/',
                name: 'Accueil',
                component: Accueil
            },
            ...patientRoutes,
            ...clientRoutes,
            ...factureRoutes,
            ...avoirFactureRoutes,
            ...utilisateurRoutes,
            ...cabinetRoutes,
            ...evenementRoutes,
            ...agendaRoutes,
            ...prestationRoutes,
            ...parametresRoutes,
            ...statistiquesRoutes,
        ]
    }, {
        path: '/acces-interdit',
        name: 'Acces interdit',
        component: NotAllowed
    }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
