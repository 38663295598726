import {del, get, post, putMultipart} from '@/services/http.service'
import {
    VacatariatCreateData,
    VacatariatPermissions,
    VacatariatReadResource,
    VacatariatUpdateResource
} from '@/model/vacatariat'
import {UtilisateurTableVacatariat} from '@/model/utilisateur'
import {getEntiteLogoById} from '@/services/entite.service'


export async function getVacatariatById(id: string): Promise<VacatariatReadResource> {
    const [ resource, logo ] = await Promise.all([get.value<VacatariatReadResource>(`/api/vacatariat/${id}`), getEntiteLogoById(id)])
    return {
        ...resource,
        entite: {
            ...resource.entite,
            logo: logo
        }
    }
}

export function findUtilisateurVacatariatsByUtilisateurId(utilisateurId: string): Promise<UtilisateurTableVacatariat[]> {
    return get.value<UtilisateurTableVacatariat[]>('/api/vacatariat', {utilisateurId})
}

export function createVacatariat(vacatariat: VacatariatCreateData): Promise<void> {
    return post.value('/api/vacatariat', vacatariat)
}

export function updateVacatariatById(id: string, resource: VacatariatUpdateResource): Promise<void> {
    const { entite : { logo, ...entite }, ...vacatariat } = resource
    const formData = new FormData()
    formData.append("resource", JSON.stringify({
        ...vacatariat,
        entite
    }))
    if(logo != null) {
        formData.append("logo", logo)
    }
    return putMultipart.value(`/api/vacatariat/${id}`,formData)
}

export function deleteVacatariatById(id: string): Promise<void> {
    return del.value(`/api/vacatariat/${id}`)
}

export async function getVacatariatPermissionsById(id: string): Promise<VacatariatPermissions> {
    return get.value(`/api/vacatariat/${id}/permissions`)
}
