import {DataResource, SearchResult} from '@/model/common'
import {del, get, getPaginated, post, put} from '@/services/http.service'
import {
    AvoirFactureCriteria,
    AvoirFactureGenerateModel,
    AvoirFactureSendReadData,
    AvoirFactureSendWriteData,
    AvoirFactureUpdateData, FactureAvoirFactureCriteria, FactureLibreAvoirFactureCriteria,
    TableAvoirFacture
} from '@/model/avoir'
import {FactureGenerateAvoirModel} from '@/model/facture'

export function searchTablePaginatedAvoirFactures(criteria: AvoirFactureCriteria): Promise<SearchResult<TableAvoirFacture>> {
    return getPaginated.value('/api/avoir-facture/_search', buildParamsFromAvoirFactureCriteria(criteria))
}

export function getAvoirFactureSendDataById(id: string): Promise<AvoirFactureSendReadData> {
    return get.value(`/api/avoir-facture/${id}/sendData`)
}

export function sendAvoirFactureById(id: string, data: AvoirFactureSendWriteData) {
    return post.value(`/api/avoir-facture/${id}/_send`, data)
}

export function soldeAvoirFactureById(id: string) {
    return post.value(`/api/avoir-facture/${id}/_solde`)
}

export function deleteAvoirFactureById(id: string): Promise<void> {
    return del.value(`/api/avoir-facture/${id}`)
}

export function getAvoirFactureLatestDate(): Promise<string|null> {
    return get.value<DataResource<string|null>>('/api/avoir-facture/_latestFactureDate')
        .then(({ data }) => data)
}

export function getAvoirFactureGenerateModelById(id: string): Promise<AvoirFactureGenerateModel> {
    return get.value(`/api/avoir-facture/${id}/model`)
}

export function updateAvoirFactureById(id: string, data: AvoirFactureUpdateData): Promise<void> {
    return put.value(`/api/avoir-facture/${id}`, data)
}

export function generateAvoirFactureById(id: string): Promise<void> {
    return post.value(`/api/avoir-facture/${id}/_generate`)
}

export function findTableAvoirFacturesByIds(ids: string[]): Promise<TableAvoirFacture[]> {
    return get.value(`/api/avoir-facture`, { ids: ids.join(',') })
}

export function findFactureGenerateAvoirModelsByAvoirFacturesIds(factureIds: string[]): Promise<FactureGenerateAvoirModel[]> {
    return get.value(`/api/avoir`, { factureIds: factureIds.join(',') })
}

export function searchTablePaginatedAvoirFacturesForFacture(criteria: FactureAvoirFactureCriteria): Promise<SearchResult<TableAvoirFacture>> {
    return getPaginated.value('/api/avoir-facture/_searchForFacture', buildParamsFromFactureAvoirFactureCriteria(criteria))
}

export function searchTablePaginatedAvoirFacturesForFactureLibre(criteria: FactureLibreAvoirFactureCriteria): Promise<SearchResult<TableAvoirFacture>> {
    return getPaginated.value('/api/avoir-facture/_searchForFactureLibre', buildParamsFromFactureLibreAvoirFactureCriteria(criteria))
}

export function getAvoirFactureByAnneeAndNumero(entiteId: string, annee: number, numero: number): Promise<string|null> {
    return get.value<DataResource<string|null>>('/api/avoir-facture/_numero', {
        entiteId: entiteId,
        annee: annee.toString(),
        numero: numero.toString()
    }).then(({ data }) => data)
}

export function getAvoirFactureByAnneeAndNextNumero(annee: number): Promise<string|null> {
    return get.value<DataResource<string|null>>('/api/avoir-facture/_numero', {
        annee: annee.toString()
    }).then(({ data }) => data)
}

function buildParamsFromAvoirFactureCriteria(criteria: AvoirFactureCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if (criteria.filters.statut) params.statut = criteria.filters.statut
    if (criteria.filters.debutAfter) params.debutAfter = criteria.filters.debutAfter
    if (criteria.filters.finBefore) params.finBefore = criteria.filters.finBefore
    if (criteria.filters.destinataire) params.destinataire = criteria.filters.destinataire
    return params
}

function buildParamsFromFactureAvoirFactureCriteria(criteria: FactureAvoirFactureCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if (criteria.filters.debutAfter) params.debutAfter = criteria.filters.debutAfter
    if (criteria.filters.finBefore) params.finBefore = criteria.filters.finBefore
    if (criteria.filters.destinataire) params.destinataire = criteria.filters.destinataire
    params.soldeFactureId = criteria.filters.soldeFactureId
    return params
}

function buildParamsFromFactureLibreAvoirFactureCriteria(criteria: FactureLibreAvoirFactureCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if (criteria.filters.debutAfter) params.debutAfter = criteria.filters.debutAfter
    if (criteria.filters.finBefore) params.finBefore = criteria.filters.finBefore
    if (criteria.filters.destinataire) params.destinataire = criteria.filters.destinataire
    return params
}