import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.shown)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#app"
      }, [
        _createElementVNode("div", {
          class: "overlay flex justify-content-center align-items-start",
          style: _normalizeStyle({'z-index': _ctx.zIndex})
        }, [
          _createElementVNode("div", {
            class: "dialog",
            style: _normalizeStyle({'width': _ctx.witdh, 'z-index': _ctx.zIndex + 1})
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 4)
        ], 4)
      ]))
    : _createCommentVNode("", true)
}