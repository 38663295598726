import {RouteLocationNormalized, RouteRecordRaw} from "vue-router";
import {reactive} from "vue";
import {Parametres} from "@/model/parametres";
import {getParametres} from "@/services/parametres.service";
import ParametresDetails from "@/views/admin/parametres/ParametresDetails.vue"

const routes = [{
    path: 'parametres',
    name: 'Parametres',
    component: ParametresDetails,
    async beforeEnter(to, from, next) {
        to.meta.parametres = await getParametres()
        next();
    },
    props: (route: RouteLocationNormalized) => ({
        parametres: reactive(route.meta.parametres as Parametres),
    })
}] as Array<RouteRecordRaw>;

export default routes;
