import {createApp, DirectiveBinding, FunctionDirective} from 'vue'
import App from './App.vue'
// import './registerServiceWorker' Enable when ready
import router from './router'

if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
            for(const registration of registrations) {
                registration.unregister();
            }
        });
}

const styles = [
    'justify-self',
    'align-self',
    'grid-column',
    'flex-grow',
    'flex-shrink',
    'flex-basis',
    'width',
    'max-width',
    'margin-top'
]

const buildStyleDirective = (style: string): FunctionDirective => (el: HTMLElement, binding: DirectiveBinding<string>) => {
    if(binding.value) {
        el.style.setProperty(style, binding.value)
    } else if(binding.arg) {
        el.style.setProperty(style, binding.arg)
    }
}

const tooltipDirective: FunctionDirective = (el, binding) => {
    const tooltipEl = document.createElement("div");
    tooltipEl.style.setProperty("position", "absolute");
    tooltipEl.style.setProperty("top", "-30px");
    tooltipEl.style.setProperty("left", "-30px");
    tooltipEl.style.setProperty("display", "none");
    tooltipEl.style.setProperty("padding", "0.5rem");
    tooltipEl.style.setProperty("color", "white");
    tooltipEl.style.setProperty("font-size", "0.8rem");
    tooltipEl.style.setProperty("background-color", "black");
    tooltipEl.style.setProperty("white-space", "nowrap");
    tooltipEl.appendChild(document.createTextNode(binding.value));
    const span = document.createElement("span");
    span.style.setProperty('position', 'relative');
    span.style.setProperty('white-space', 'no-wrap');
    span.appendChild(tooltipEl);
    el.parentElement.replaceChild(span, el);
    span.appendChild(el)
    span.onmouseenter = () => tooltipEl.style.setProperty("display", "block");
    span.onmouseleave = () => tooltipEl.style.setProperty("display", "none");
}

const app = createApp(App)
    .use(router)
    .directive('tooltip', tooltipDirective)
styles.forEach(style => app.directive(style, buildStyleDirective(style)));
app.mount('#app');

