import {PaginationCriteria, Sort} from '@/model/common'
import {EvenementType} from '@/model/evenement'

export type TacheType =
    'VALIDATION_RDV' |
    'ACCEPTATION_BILAN' |
    'VALIDATION_BILAN' |
    'SAISIE_DENOUEMENT_NOT_BILAN_PRESTATION_RDV_EVENEMENT' |
    'SAISIE_DENOUEMENT_BILAN_PRESTATION_RDV_EVENEMENT' |
    'SAISIE_DENOUEMENT_COLLABORATEUR_VACATAIRE_BILAN_PRESTATION_RDV_EVENEMENT' |
    'SAISIE_DENOUEMENT_ASSOCIE_BILAN_PRESTATION_RDV_EVENEMENT' |
    'SAISIE_DENOUEMENT_BILAN_EVENEMENT' |
    'SAISIE_DENOUEMENT_COLLABORATEUR_VACATAIRE_BILAN_EVENEMENT' |
    'SAISIE_DENOUEMENT_ASSOCIE_BILAN_EVENEMENT' |
    'SAISIE_BILAN_EVENEMENT_COMPTE_RENDU_BILAN' |
    'SAISIE_RDV_EVENEMENT_COMPTE_RENDU_BILAN' |
    'SAISIE_MONTANT_SOUS_TRAITANCE_BILAN' |
    'SAISIE_MONTANT_SOUS_TRAITANCE_VACATION';

export const tacheTypeLabelMap: Record<TacheType, string> = {
    SAISIE_BILAN_EVENEMENT_COMPTE_RENDU_BILAN: 'Saisie CR de bilan',
    SAISIE_RDV_EVENEMENT_COMPTE_RENDU_BILAN: 'Saisie CR de bilan',
    SAISIE_DENOUEMENT_NOT_BILAN_PRESTATION_RDV_EVENEMENT: 'Saisie dénouement rendez-vous',
    SAISIE_DENOUEMENT_BILAN_PRESTATION_RDV_EVENEMENT: 'Saisie dénouement rendez-vous de bilan',
    SAISIE_DENOUEMENT_COLLABORATEUR_VACATAIRE_BILAN_PRESTATION_RDV_EVENEMENT: 'Saisie dénouement rendez-vous de bilan',
    SAISIE_DENOUEMENT_ASSOCIE_BILAN_PRESTATION_RDV_EVENEMENT: 'Saisie dénouement rendez-vous de bilan',
    SAISIE_DENOUEMENT_BILAN_EVENEMENT: 'Saisie dénouement bilan',
    SAISIE_DENOUEMENT_COLLABORATEUR_VACATAIRE_BILAN_EVENEMENT: 'Saisie dénouement bilan',
    SAISIE_DENOUEMENT_ASSOCIE_BILAN_EVENEMENT: 'Saisie dénouement bilan',
    SAISIE_MONTANT_SOUS_TRAITANCE_BILAN: 'Saisie tarif sous-traitance bilan',
    SAISIE_MONTANT_SOUS_TRAITANCE_VACATION: 'Saisie tarif sous-traitance vacation',
    VALIDATION_RDV: 'Validation rendez-vous collaborateur',
    ACCEPTATION_BILAN: 'Validation créneau bilan collaborateur',
    VALIDATION_BILAN: 'Validation du bilan'
}

export interface TableTache {
    id: string,
    date: string,
    type: TacheType,
    initiateur: string,
    destinataire: string,
    evenementId: string | null,
    evenementTitre: string | null,
    evenementType: EvenementType | null,
    evenementDebut: string,
    evenementFin: string,
    cabinetLieu: boolean | null,
    prestationId: string | null,
    prestationDescription: string | null,
    patientId: string | null,
    patientDenomination: string | null,
    client: string | null,
}

export interface TacheCriteriaFilters{
    initiateur: string | null,
}

export interface TacheCriteria{
    filters: TacheCriteriaFilters,
    sort: Sort | null,
    pagination: PaginationCriteria,
}
