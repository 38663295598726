import {endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek} from "date-fns";

export type CalendarMode = 'MONTH' | 'WEEK' | 'DAY'
export const calendarModeLabelMap: Record<CalendarMode, string> = {
    MONTH: 'Mois',
    WEEK: 'Semaine',
    DAY: 'Jour'
}

export interface CalendarEvent {
    id: string,
    type: string,
    label: string | null,
    start: string,
    end: string,
    data: unknown | null,
    highlightable: boolean
}

export interface CalendarLegend {
    label: string,
    style: Record<string, string>,
}

export type CalendarLegends = Record<string, CalendarLegend>

export const modePeriodMap: Record<CalendarMode, {
    endOf: (d: Date) => Date,
    startOf: (d: Date) => Date
}> = {
    MONTH: {endOf: endOfMonth, startOf: startOfMonth },
    WEEK: {endOf: date => endOfWeek(date, {weekStartsOn: 1}), startOf: date => startOfWeek(date, {weekStartsOn: 1})},
    DAY: {endOf: endOfDay, startOf: startOfDay },
}

export interface CalendarElement {
    event: CalendarEvent,
    style: Record<string, string>,
    duration: string,
    label: string | null,
    highlightable: boolean
}
