import {RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import RdvEvenementCreate from '@/views/admin/evenement/create/RdvEvenementCreate.vue'
import RdvEvenementUpdate from '@/views/admin/evenement/update/RdvEvenementUpdate.vue'
import BilanEvenementCreate from '@/views/admin/evenement/create/BilanEvenementCreate.vue'
import BilanEvenementUpdate from '@/views/admin/evenement/update/BilanEvenementUpdate.vue'
import ReunionEvenementCreate from '@/views/admin/evenement/create/ReunionEvenementCreate.vue'
import ReunionEvenementUpdate from '@/views/admin/evenement/update/ReunionEvenementUpdate.vue'
import EvenementList from '@/views/admin/evenement/EvenementList.vue'
import {handleBeforeEnterHttpErrors} from '@/router/RouteUtils'
import {getBilanEvenementById, getEvenementById, getRdvEvenementById} from '@/services/evenement.service'

const routes = [{
    path: '/evenements',
    name: 'Evenements',
    component: EvenementList
}, {
    path: '/evenements/rendez-vous/nouveau',
    name: 'Evenements rendez-vous nouveau',
    component: RdvEvenementCreate
}, {
    path: '/evenements/rendez-vous/:id',
    name: 'Evenements rendez-vous update',
    component: RdvEvenementUpdate,
    beforeEnter: handleBeforeEnterHttpErrors(async (to, from, next) => {
        to.meta.evenement = await getRdvEvenementById(to.params.id as string)
        next()
    }),
    props: (route: RouteLocationNormalized) => ({
        id: route.params.id as string,
        evenement: route.meta.evenement
    })
}, {
    path: '/evenements/bilan/nouveau',
    name: 'Evenements bilan nouveau',
    component: BilanEvenementCreate
}, {
    path: '/evenements/bilan/:id',
    name: 'Evenements bilan update',
    component: BilanEvenementUpdate,
    beforeEnter: handleBeforeEnterHttpErrors(async (to, from, next) => {
        to.meta.evenement = await getBilanEvenementById(to.params.id as string)
        next()
    }),
    props: (route: RouteLocationNormalized) => ({
        id: route.params.id as string,
        evenement: route.meta.evenement
    })
}, {
    path: '/evenements/reunion/nouvelle',
    name: 'Evenements reunion nouvelle',
    component: ReunionEvenementCreate
}, {
    path: '/evenements/reunion/:id',
    name: 'Evenements reunion update',
    component: ReunionEvenementUpdate,
    beforeEnter: handleBeforeEnterHttpErrors(async (to, from, next) => {
        to.meta.evenement = await getEvenementById(to.params.id as string)
        next()
    }),
    props: (route: RouteLocationNormalized) => ({
        id: route.params.id as string,
        evenement: route.meta.evenement
    })
}] as Array<RouteRecordRaw>

export default routes;
