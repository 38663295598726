
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'OptGroup',
  props: {
    label: {
      type: String,
      required: true
    }
  }
})
