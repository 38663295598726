import {Adresse, Civilite, PaginationCriteria, Sort} from '@/model/common'
import {PatientFacturation, LieuPriseEnChargeType} from '@/model/dossier/patient'
import {PrestationReadData, PrestationWriteData} from '@/model/prestation'
import {Reglement} from '@/model/reglement'
import {CalendarLegends} from '@/model/calendar'

export type EvenementType = 'RDV' | 'BILAN' | 'REUNION' | 'CONGES_ABSENCE'
export const evenementTypeLabelMap: Record<EvenementType, string> = {
    BILAN: 'Bilan',
    CONGES_ABSENCE: 'Congés / absence',
    RDV: 'Rendez-vous',
    REUNION: 'Réunion'
}

export interface EvenementWriteData {
    titre: string,
    debut: string,
    fin: string,
    utilisateurId: string
}

export interface EvenementReadData {
    titre: string,
    debut: string,
    fin: string,
    utilisateurId: string,
    cabinetId: string | null
}

export type ClientInterventionType = 'PATIENTS' | 'CLIENT'
export const clientInterventionTypeLabelMap: Record<ClientInterventionType, string> = {
    PATIENTS: 'Acte(s) Patient(s)',
    CLIENT: 'Prestation client'
}

export type ClientLieuType = 'CABINET' | 'CLIENT' | 'AUTRE'
export const clientLieuTypeLabelMap: Record<ClientLieuType, string> = {
    CABINET: 'Cabinet',
    CLIENT: 'Chez le client',
    AUTRE: 'Autre'
}

export interface RdvEvenementWriteData {
    evenement: EvenementWriteData,
    patientId: string | null,
    patientLieuPriseEnChargeNom: string | null,
    patientLieuPriseEnChargeType: LieuPriseEnChargeType | null,
    clientId: string | null,
    clientInterventionType: ClientInterventionType | null,
    clientLieuType: ClientLieuType | null,
    clientLieuNom: string | null,
    adresse: Adresse | null,
    prestations: PrestationWriteData[]
}

export interface RdvEvenementReadData {
    evenement: EvenementReadData,
    patientId: string | null,
    patientLieuPriseEnChargeNom: string | null,
    patientLieuPriseEnChargeType: LieuPriseEnChargeType | null,
    clientId: string | null,
    clientInterventionType: ClientInterventionType | null,
    clientLieuType: ClientLieuType | null,
    clientLieuNom: string | null,
    adresse: Adresse | null,
    prestations: PrestationReadData[]
}

export interface BilanEvenementWriteData {
    evenement: EvenementWriteData
}

export interface BilanEvenementReadData {
    evenement: EvenementReadData
}

export interface ReunionEvenementWriteData {
    evenement: EvenementWriteData
}

export type EvenementStatut = 'ANNULE' | 'REFUSE' | 'ATTENTE_ACCEPTATION' | 'NON_REPONDU_ACCEPTATION' | 'PLANIFIE' | 'EN_COURS' | 'ATTENTE_DENOUEMENT' | 'SURVENU' | 'NON_SURVENU'
    | 'LIBRE' | 'ATTENTE_VALIDATION' | 'RESERVE' | 'RESERVATION_REFUSEE' | 'PASSE' | 'NON_REPONDU_PATIENT_CABINET'
    | 'ATTENTE_SAISIE_CR_MONTANT_SOUS_TRAITANCE_BILAN' | 'ATTENTE_SAISIE_CR' | 'ATTENTE_SAISIE_MONTANT_SOUS_TRAITANCE_BILAN' | 'ATTENTE_SAISIE_MONTANT_SOUS_TRAITANCE_VACATION' | 'ANNULE_ADMIN' | 'ANNULE_PATIENT'
    | 'TERMINE';
export const evenementStatutLabelMap: Record<EvenementStatut, string> = {
    ATTENTE_SAISIE_CR: 'Attente saisie compte-rendu',
    ATTENTE_SAISIE_CR_MONTANT_SOUS_TRAITANCE_BILAN: 'Attente saisie compte-rendu et tarif sous-traitance bilan',
    ATTENTE_SAISIE_MONTANT_SOUS_TRAITANCE_BILAN: 'Attente saisie tarif sous-traitance bilan',
    ATTENTE_SAISIE_MONTANT_SOUS_TRAITANCE_VACATION: 'Attente saisie tarif sous-traitance vacation',
    PLANIFIE: 'Planifié',
    EN_COURS: 'En cours',
    ANNULE: 'Annulé',
    ATTENTE_DENOUEMENT: 'Attente de dénouement',
    ATTENTE_ACCEPTATION: 'Attente acceptation créneau cabinet',
    NON_REPONDU_ACCEPTATION: 'Absence réponse acceptation créneau cabinet',
    NON_SURVENU: 'Non survenu',
    REFUSE: 'Créneau refusé',
    SURVENU: 'Survenu',
    ATTENTE_VALIDATION: 'Réservé (Attente validation)',
    LIBRE: 'Libre',
    NON_REPONDU_PATIENT_CABINET: 'Absence réponse cabinet au patient',
    PASSE: 'Passé',
    RESERVE: 'Réservé',
    RESERVATION_REFUSEE: 'Réservation refusée',
    ANNULE_ADMIN: 'Annulé (En administration)',
    ANNULE_PATIENT: 'Annulé (Par le patient)',
    TERMINE: 'Terminé(e)'
}

export interface TableEvenement{
    id: string,
    debut: string,
    fin: string,
    type: EvenementType,
    titre: string,
    patientLieuPriseEnChargeType: LieuPriseEnChargeType | null,
    clientLieuType: ClientLieuType | null,
    utilisateurId: string | null,
    utilisateurDenomination: string | null,
    patientId: string | null,
    patientDenomination: string | null,
    client: string | null,
    statut: EvenementStatut,
    prestationId: string | null,
    bilanPrestation: boolean
}

export interface AgendaEvenementCriteria {
    debutAfter: string,
    finBefore: string
}

export interface EvenementCriteriaFilters {
    debutAfter: string | null,
    finBefore: string | null,
    currentMonth: boolean,
    type: EvenementType | null,
    titre: string | null,
    utilisateurDenomination: string | null,
    patientDenomination: string | null,
    statut: EvenementStatut | null
}

export interface PaginatedEvenementCriteria extends EvenementCriteria {
    filters: EvenementCriteriaFilters,
    pagination: PaginationCriteria,
    sort: Sort | null
}

export interface EvenementCriteria {
    filters: EvenementCriteriaFilters
}

export interface ReservationBilanEvenementCriteria {
    debutAfter: string,
    finBefore: string,
}


type ReservationBilanEvenementStatutEnum = 'PRIS' |  'PASSE' |  'LIBRE'

export interface TableReservationBilanEvenement {
    id: string,
    statut: ReservationBilanEvenementStatutEnum,
    debut: string,
    fin: string
}

export interface BilanEvenementReservationCreateData {
    civilitePatient: Civilite,
    nomPatient: string,
    prenomPatient: string,
    nomParent: string | null,
    dateNaissancePatient: string,
    telephone: string,
    email: string,
    adresse: Adresse,
    motif: string,
    prevenirCreneauLibre: boolean
}

export interface BilanEvenementReservationReadData {
    date: string,
    civilitePatient: Civilite,
    nomPatient: string,
    prenomPatient: string,
    nomParent: string | null,
    dateNaissancePatient: string,
    telephone: string,
    email: string,
    adresse: Adresse,
    motif: string,
    prevenirCreneauLibre: boolean
}

export type EvenementDuplicationStep = 'DAY' | 'WEEK' | 'TWO_WEEKS' | 'MONTH'
export const evenementDuplicationStepLabelMap: Record<EvenementDuplicationStep, string> = {
    DAY: 'Tous les jours',
    WEEK: 'Chaque semaine',
    TWO_WEEKS: 'Toutes les deux semaines',
    MONTH: 'Chaque mois'
}
export const evenementDuplicationStepOffsetMap: Record<Exclude<EvenementDuplicationStep, 'MONTH'>, number> = {
    DAY: 1,
    WEEK: 7,
    TWO_WEEKS: 14
}

export interface EvenementDuplicationData {
    step: EvenementDuplicationStep,
    fin: string
}

export interface BilanEvenementDenouementData {
    survenu: boolean,
    data: BilanEvenementDenouementSurvenuData | null
}

export interface AssocieBilanEvenementDenouementData {
    survenu: boolean,
    data: AssocieBilanEvenementDenouementSurvenuData | null
}

export interface BilanPrestationRdvEvenementDenouementData {
    survenu: boolean,
    data: BilanPrestationRdvEvenementDenouementSurvenuData | null
}

export interface AssocieBilanPrestationRdvEvenementDenouementData {
    survenu: boolean,
    data: AssocieBilanPrestationRdvEvenementDenouementSurvenuData | null
}

export interface BilanEvenementDenouementSurvenuData {
    tarif: number,
    facturation: PatientFacturation,
    reglements: Reglement[]
}

export interface AssocieBilanEvenementDenouementSurvenuData {
    tarif: number,
    sousTraitanceMontant: number,
    facturation: PatientFacturation,
    reglements: Reglement[]
}

export interface BilanPrestationRdvEvenementDenouementSurvenuData {
    tarif: number,
    facturation: PatientFacturation,
    reglements: Reglement[]
}

export interface AssocieBilanPrestationRdvEvenementDenouementSurvenuData {
    tarif: number,
    sousTraitanceMontant: number,
    facturation: PatientFacturation,
    reglements: Reglement[]
}

export interface BilanEvenementReservationConfirmationWriteData {
    destinataires: string[],
    carbonCopies: string[],
    subject: string,
    content: string
}

export interface BilanEvenementReservationRefusWriteData {
    destinataires: string[],
    carbonCopies: string[],
    subject: string,
    content: string
}

export interface BilanEvenementReservationAnnulationWriteData {
    destinataires: string[],
    carbonCopies: string[],
    subject: string,
    content: string
}

export interface BilanEvenementReservationConfirmationReadData {
    date: string,
    prestataireDenomination: string,
    utilisateurDenomination: string,
    utilisateurTelephone: string,
    confirmation: string | null,
    patientEmail: string,
    lieu: string | null,
    adresse: Adresse,
    cancelLink: string
}

export interface BilanEvenementReservationRefusReadData {
    date: string,
    prestataireDenomination: string,
    patientEmail: string,
    reservationLink: string
}

export interface BilanEvenementReservationAnnulationReadData {
    date: string,
    prestataireDenomination: string,
    patientEmail: string
}

export interface CabinetEvenenementAcceptData {
    rdvEvenementIds: string[],
    bilanEvenementIds: string[],
    accepte: boolean
}

export interface EvenenementAcceptData {
    rdvEvenementIds: string[],
    accepte: boolean
}

export interface TableCongesAbsence {
    id: string,
    debut: string,
    fin: string,
    titre: string
}

export interface CongesAbsenceCriteriaFilters {
    debutAfter: string | null,
    finBefore: string | null
}

export interface CongesAbsenceCriteria {
    filters: CongesAbsenceCriteriaFilters,
    pagination: PaginationCriteria
}

export interface EvenementExistsData {
    utilisateurId: string,
    debut: string,
    fin: string,
    id: string | null
}

export interface CongesAbsenceExistsData {
    utilisateurId: string,
    debut: string,
    fin: string
}

export const legends: CalendarLegends = {
  'RDV_ATTENTE_ACCEPTATION': { // OK
    label: 'Rendez-vous en attente d\'acceptation',
    style: {
      'color': 'black',
      'font-weight': 'bold',
      'background-color': '#ead702',
      'z-index': '100'
    }
  },
  'RDV_PLANIFIE_ATTENTE_DENOUEMENT': { // OK
    label: 'Rendez-vous planifié / en attente de dénouement',
    style: {
      'color': 'black',
      'font-weight': 'bold',
      'background': '#8aef67',
      'z-index': '100'
    }
  },
  'RDV_BILAN_ANNULE_NON_SURVENU': { // OK
    label: 'Rendez-vous / Bilan annulé / non survenu',
    style: {
      'color': 'black',
      'font-weight': 'bold',
      'background-color': '#d5d5d5',
      'z-index': '90'
    }
  },
  'RDV_SURVENU': { // OK
    label: 'Rendez-vous survenu',
    style: {
      'color': 'black',
      'font-weight': 'bold',
      'background': '#5cb85c',
      'background-size': '20px 20px',
      'z-index': '100'
    }
  },
  'BILAN_ATTENTE_ACCEPTATION_ATTENTE_VALIDATION': { // OK
    label: 'Bilan en attente d\'acceptation / de validation de réservation',
    style: {
      'color': 'black',
      'font-weight': 'bold',
      'background-color': '#ff892a',
      'z-index': '100'
    }
  },
  'BILAN_LIBRE': { // OK
    label: 'Bilan libre',
    style: {
      'color': 'black',
      'font-weight': 'bold',
      'background-color': '#8dcafd',
      'z-index': '100'
    }
  },
  'BILAN_RESERVE_ATTENTE_DENOUEMENT_SURVENU': { // OK
    label: 'Bilan réservé / en attente de dénouement / survenu',
    style: {
      'color': 'black',
      'font-weight': 'bold',
      'background-color': '#478fca',
      'z-index': '100'
    }
  },
  'REUNION': { // OK
    label: 'Réunion au cabinet',
    style: {
      'font-weight': 'bold',
      'background-color': '#ab273c',
      'z-index': '100'
    }
  },
  'CONGES_ABSENCE': { // OK
    label: 'Congés / Absence',
    style: {
      'background-color': '#baace0',
      'z-index': '80'
    }
  }
}
