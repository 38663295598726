import {SearchResult} from '@/model/common'
import {del, get, getPaginated, post, put} from '@/services/http.service'
import {
    RemplacementCreateData,
    RemplacementCriteria, RemplacementReadData,
    RemplacementUpdateData,
    TableRemplacement
} from '@/model/remplacement'

export function searchTablePaginatedRemplacements(criteria: RemplacementCriteria): Promise<SearchResult<TableRemplacement>> {
    return getPaginated.value<TableRemplacement>('/api/remplacement/_search', buildParamsFromRemplacementCriteria(criteria))
}

export function getRemplacementById(id: string): Promise<RemplacementReadData> {
    return get.value(`/api/remplacement/${id}`)
}

export function createRemplacement(data: RemplacementCreateData): Promise<void> {
    return post.value(`/api/remplacement`, data)
}

export function updateRemplacementById(id: string, data: RemplacementUpdateData): Promise<void> {
    return put.value(`/api/remplacement/${id}`, data)
}

export function deleteRemplacementById(id: string): Promise<void> {
    return del.value(`/api/remplacement/${id}`)
}

function buildParamsFromRemplacementCriteria(criteria: RemplacementCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if(criteria.filters.remplace) params.remplace = criteria.filters.remplace
    if(criteria.filters.remplacant) params.remplacant = criteria.filters.remplacant
    return params
}
