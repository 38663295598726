import {Parametres} from "@/model/parametres";
import {get, put} from "@/services/http.service";
import {DataResource} from '@/model/common'

export function getParametres(): Promise<Parametres> {
    return get.value(`/api/parametres`)
}

export function getParametresTvaTaux(): Promise<number> {
    return get.value<DataResource<number>>(`/api/parametres/tva/taux`)
        .then(({ data }) => data)
}

export function updateParametres(parametres: Parametres): Promise<void> {
    return put.value(`/api/parametres`, parametres)
}