
import {defineComponent} from 'vue';
import Label from "@/components/common/label/Label.vue";
import {cssClass, message, shown} from "@/services/toast.service";
export default defineComponent({
  name: 'Toast',
  components: {
    Label
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      shown,
      message,
      cssClass
    }
  }
})
