import {SearchResult, Sort} from "@/model/common";
import {activeAuth, perimetre, utilisateurId} from '@/services/authentication.service'
import {computed} from "vue";

export class HttpError extends Error {
    status: number
    constructor(status: number) {
        super('An HTTP error occured.')
        this.status = status;
    }
}

const jsonHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8'
}

const impersonationHeaders = computed(() => {
    if (activeAuth.value !== null) {
        return {
            'Impersonation-Id': activeAuth.value?.id
        } as {[key: string]: string}
    }
    return {} as {[key: string]: string}
})

const perimetreHeaders = computed(() => {
    const headers = {} as {[key: string]: string}
    const perimetreValue = perimetre.value
    if (perimetreValue !== null) {
        headers['Perimetre-Type'] = perimetreValue.type
        switch (perimetreValue.type) {
            case "ASSOCIATION":
                headers['Perimetre-Entite-Id'] = perimetreValue.associationId;
                headers['Perimetre-Etablissement-Id'] = perimetreValue.etablissementId;
                headers['Perimetre-Cabinet-Id'] = perimetreValue.cabinet.id;
                headers['Perimetre-Cabinet-Entite-Id'] = perimetreValue.cabinet.id;
                headers['Perimetre-Cabinet-Etablissement-Id'] = perimetreValue.cabinet.etablissementId;
                headers['Perimetre-Cabinet-Gestionnaire-Dossier-Id'] = perimetreValue.cabinet.id;
                break;
            case "GERANCE":
                headers['Perimetre-Cabinet-Id'] = perimetreValue.cabinet.id;
                headers['Perimetre-Cabinet-Entite-Id'] = perimetreValue.cabinet.id;
                headers['Perimetre-Cabinet-Etablissement-Id'] = perimetreValue.cabinet.etablissementId;
                headers['Perimetre-Cabinet-Gestionnaire-Dossier-Id'] = perimetreValue.cabinet.id;
                break;
            case "COLLABORATION":
                headers['Perimetre-Cabinet-Id'] = perimetreValue.cabinet.id;
                headers['Perimetre-Cabinet-Entite-Id'] = perimetreValue.cabinet.id;
                headers['Perimetre-Cabinet-Etablissement-Id'] = perimetreValue.cabinet.etablissementId;
                headers['Perimetre-Entite-Id'] = perimetreValue.collaborationId;
                headers['Perimetre-Etablissement-Id'] = perimetreValue.etablissementId;
                headers['Perimetre-Cabinet-Gestionnaire-Dossier-Id'] = perimetreValue.cabinet.id
                break;
            case "VACATARIAT":
                headers['Perimetre-Cabinet-Id'] = perimetreValue.cabinet.id;
                headers['Perimetre-Cabinet-Entite-Id'] = perimetreValue.cabinet.id;
                headers['Perimetre-Cabinet-Etablissement-Id'] = perimetreValue.cabinet.etablissementId;
                headers['Perimetre-Entite-Id'] = perimetreValue.vacatariatId;
                headers['Perimetre-Etablissement-Id'] = perimetreValue.etablissementId;
                headers['Perimetre-Cabinet-Gestionnaire-Dossier-Id'] = perimetreValue.cabinet.id
                break;
            case "INDEPENDANT":
                headers['Perimetre-Entite-Id'] = utilisateurId.value as string;
                headers['Perimetre-Etablissement-Id'] = perimetreValue.etablissementId as string;
                headers['Perimetre-Gestionnaire-Dossier-Id'] = utilisateurId.value as string;
                break;
        }
    }

    return headers
})

const multiPartHeaders = {
    'Accept': 'application/json'
}


export const post = computed(() => async <T>(url: string, data: unknown = null, headers: {[key: string]: string} = {}): Promise<T> => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            ...headers,
            ...impersonationHeaders.value,
            ...jsonHeaders,
            ...perimetreHeaders.value,
        },
        body: JSON.stringify(data)
    })

    if (response.ok) {
        const body = await response.text()
        if (body) {
            return JSON.parse(body) as T
        }
        return null as unknown as T
    } else {
        throw new HttpError(response.status)
    }
})

export const del = computed(() => async <T>(url: string): Promise<T> => {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: { ...jsonHeaders, ...impersonationHeaders.value, ...perimetreHeaders.value },
    })

    if (response.ok) {
        const body = await response.text()
        if (body) {
            return JSON.parse(body) as T
        }
        return null as unknown as T
    } else {
        throw new HttpError(response.status)
    }
});

export const put = computed(() => async (url: string, data: unknown): Promise<void> => {
    const response = await fetch(url, {
        method: 'PUT',
        headers: { ...jsonHeaders, ...impersonationHeaders.value, ...perimetreHeaders.value },
        body: JSON.stringify(data)
    })
    if (!response.ok) {
        throw new HttpError(response.status)
    }
});

export const get = computed(() => async <R>(url: string, params: { [key: string]: string } = {}, headers: {[key: string]: string} = {}): Promise<R> => {
    const response = await fetch(`${url}?${new URLSearchParams(params)}`, {
        headers: {
            ...headers,
            ...impersonationHeaders.value,
            ...jsonHeaders,
            ...perimetreHeaders.value,
        }
    })
    if (response.ok) {
        const body = await response.text()
        if (body) {
            return JSON.parse(body) as R
        }
        return null as unknown as R
    } else {
        throw new HttpError(response.status)
    }
});

export const getOrNull = computed(() => async <R>(url: string, params: { [key: string]: string } = {}): Promise<R|null> => {
    const response = await fetch(`${url}?${new URLSearchParams(params)}`, {
        headers: {
            ...jsonHeaders, ...impersonationHeaders.value, ...perimetreHeaders.value,
        }
    })
    if (response.status === 204) {
        return null
    }
    if (response.ok) {
        const body = await response.text()
        if (body) {
            return JSON.parse(body) as R
        }
        return null as unknown as R
    } else {
        throw new HttpError(response.status)
    }
});

export const getFileOrNull = computed(() => async (url: string, params: { [key: string]: string } = {}): Promise<File|null> => {
    const response = await fetch(`${url}?${new URLSearchParams(params)}`, {
        headers: {
            ...multiPartHeaders, ...impersonationHeaders.value, ...perimetreHeaders.value,
        }
    })
    if (response.ok) {
        if (response.status === 204) {
            return null
        }
        const blob = await response.blob()
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition != null ? getContentDispositionFileName(contentDisposition) : ''
        return new File([blob], filename, { type: blob.type })
    } else {
        throw new HttpError(response.status)
    }
});

export const getPaginated = computed(() => async <R>(url: string, params: { [key: string]: string } = {}): Promise<SearchResult<R>> => {
    const response = await fetch(`${url}?${new URLSearchParams(params)}`, {
        headers: {
            ...jsonHeaders, ...impersonationHeaders.value, ...perimetreHeaders.value,
        }
    })
    if (response.ok) {
        return {
            data: await response.json() as R[],
            pagination: {
                size: parseInt(response.headers.get("Pagination-Size") as string),
                total: parseInt(response.headers.get("Pagination-Total") as string),
            }
        }
    } else {
        throw new HttpError(response.status)
    }
});

export const postMultipart = computed(() => async <T>(url: string, formData: FormData): Promise<T> => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            ...multiPartHeaders, ...impersonationHeaders.value, ...perimetreHeaders.value,
        },
        body: formData
    })
    if (response.ok) {
        const body = await response.text()
        if (body) {
            return JSON.parse(body) as T
        }
        return null as unknown as T
    } else {
        throw new HttpError(response.status)
    }
})

export const putMultipart = computed(() => async <T>(url: string, formData: FormData): Promise<T> => {
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            ...multiPartHeaders, ...impersonationHeaders.value, ...perimetreHeaders.value,
        },
        body: formData
    })
    if (response.ok) {
        const body = await response.text()
        if (body) {
            return JSON.parse(body) as T
        }
        return null as unknown as T
    } else {
        throw new HttpError(response.status)
    }
});

function getContentDispositionFileName(contentDisposition: string): string {
    return /filename="(.*?)"/.exec(contentDisposition)?.[1] ?? '';
}


export function buildSortParam(sort: Sort) {
    return `${sort.column} ${sort.type}`
}
