
import {defineComponent} from 'vue';
import Label from '@/components/common/label/Label.vue';
import Grid from "@/components/common/grid/Grid.vue";
import Title from "@/components/common/title/Title.vue";
import { menuItems } from "@/services/admin.service";
import Image from "@/components/common/image/Image.vue";
import Icon from "@/components/common/icon/Icon.vue";

export default defineComponent({
  name: 'Accueil',
  components: {
    Icon,
    Image,
    Label,
    Grid,
    Title
  },
  setup() {
    const cards = menuItems
    return {
      cards
    }
  }
})
