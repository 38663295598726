import {del, get, post, putMultipart} from "@/services/http.service";
import {
    AssociationCreateResource,
    AssociationPermissions,
    AssociationReadResource,
    AssociationUpdateResource
} from '@/model/association'
import {UtilisateurTableAssociation} from "@/model/utilisateur";
import {getEntiteLogoById} from "@/services/entite.service";

export async function getAssociationById(id: string): Promise<AssociationReadResource> {
    const [ resource, logo ] = await Promise.all([get.value<AssociationReadResource>(`/api/association/${id}`), getEntiteLogoById(id)])
    return {
        ...resource,
        entite: {
            ...resource.entite,
            logo: logo
        }
    }
}

export async function getAssociationPermissionsById(id: string): Promise<AssociationPermissions> {
    return get.value(`/api/association/${id}/permissions`)
}

export function findUtilisateurAssociationsByUtilisateurId(utilisateurId: string): Promise<UtilisateurTableAssociation[]> {
    return get.value('/api/association', { utilisateurId })
}

export function createAssociation(association: AssociationCreateResource): Promise<void> {
    return post.value('/api/association', association)
}

export function updateAssociationById(id: string, resource: AssociationUpdateResource): Promise<void> {
    const { entite : { logo, ...entite }, ...association } = resource

    const formData = new FormData()
    formData.append("resource", JSON.stringify({
        ...association,
        entite
    }))
    if (logo != null) {
        formData.append("logo", logo)
    }
    return putMultipart.value(`/api/association/${id}`, formData)
}

export function deleteAssociationById(id: string): Promise<void> {
    return del.value(`/api/association/${id}`)
}
