import {
    CollaborateurTablePatient,
    GerantAssociePatientCriteria,
    GerantAssocieTablePatient,
    PatientCriteria,
    PatientDevisReadData,
    PatientDevisReadResource,
    PatientDossierMedicalFichierReadData,
    PatientExistsData,
    PatientExistsExceptIdData,
    PatientFacturation,
    PatientReadData,
    PatientReadResource,
    PatientRemplacementCriteria,
    PatientStatutTermineUpdateResource,
    PatientStatutUpdateResource,
    PatientWriteData,
    PatientWriteResource,
    VacataireTablePatient,
} from '@/model/dossier/patient'
import {buildSortParam, del, get, getFileOrNull, getPaginated, post, put, putMultipart} from '@/services/http.service'
import {DataResource, LabelData, PaginationCriteria, SearchResult} from '@/model/common'
import {gestionnaireDossierId, perimetre} from '@/services/authentication.service'
import {PatientPartageReadData, PatientPartagesUpdateData, PatientsPartagesUpdateData} from '@/model/partage'
import {AssocieDevisEmetteurType, PatientDevisCreateResource} from '@/model/devis'
import {TablePatientHistorique} from '@/model/historique'
import {FacturePatientFacturation} from '@/model/facture'

export function findLabelPatients(): Promise<LabelData[]> {
    return get.value('/api/patient')
}

export function findLabelPatientsByExistsPatientFacture(): Promise<LabelData[]> {
    return get.value('/api/patient/_existsFacture')
}

export function findLabelPatientsByExistsPatientEvenement(): Promise<LabelData[]> {
    return get.value('/api/patient/_existsEvenement')
}

export function searchOwnGerantAssociePatients(criteria: GerantAssociePatientCriteria): Promise<SearchResult<GerantAssocieTablePatient>> {
    if (perimetre.value?.type !== 'GERANCE' && perimetre.value?.type !== 'ASSOCIATION') throw new Error('Not allowed')
    return getPaginated.value('/api/patient/_search', buildParamsFromGerantAssociePatientCriteria(criteria))
}

export function searchOwnCollaborateurPatients(criteria: PatientCriteria): Promise<SearchResult<CollaborateurTablePatient>> {
    if (perimetre.value?.type !== 'COLLABORATION') throw new Error('Not allowed')
    return getPaginated.value('/api/patient/_search', buildParamsFromPatientCriteria(criteria))
}

export function searchOwnVacatairePatients(criteria: PatientCriteria): Promise<SearchResult<VacataireTablePatient>> {
    if (perimetre.value?.type !== 'VACATARIAT') throw new Error('Not allowed')
    return getPaginated.value('/api/patient/_search', buildParamsFromPatientCriteria(criteria))
}

export function searchOwnIndependantPatients(criteria: PatientCriteria): Promise<SearchResult<CollaborateurTablePatient>> {
    if (perimetre.value?.type !== 'INDEPENDANT') throw new Error('Not allowed')
    return getPaginated.value('/api/patient/_search', buildParamsFromPatientCriteria(criteria))
}

export async function getPatientWithFilesById(id: string): Promise<PatientReadData> {
    const resource = await get.value<PatientReadResource>(`/api/patient/${id}`)
    const fichiers = resource.dossierMedical.fichiers
    const files = await Promise.all(fichiers.map(({ id }) => getPatientDossierMedicalFichierById(id)))
    const fichiersWithFiles = [] as PatientDossierMedicalFichierReadData[]
    for(let i = 0; i < fichiers.length; i++) {
        if (files[i] !== null) {
            fichiersWithFiles.push({ ...fichiers[i], file: files[i] as File})
        }
    }
    return {
        ...resource,
        dossierMedical: {
            ...resource.dossierMedical,
            fichiers: fichiersWithFiles
        }
    }
}

export async function getPatientById(id: string): Promise<PatientReadData> {
    return await get.value<PatientReadData>(`/api/patient/${id}`)
}

export async function getLabelPatientById(id: string): Promise<LabelData> {
    return await get.value<LabelData>(`/api/patient/${id}`, {}, {'Representation': 'Label'})
}

export async function getPatientFacturationById(id: string): Promise<PatientFacturation> {
    return await get.value<PatientFacturation>(`/api/patient/${id}/facturation`)
}

export async function getFacturePatientFacturationById(id: string): Promise<FacturePatientFacturation> {
    return await get.value<FacturePatientFacturation>(`/api/patient/${id}/facturation`, {}, {'Representation': 'Facture'})
}

function getPatientDossierMedicalFichierById(id: string): Promise<File|null> {
    return getFileOrNull.value(`/api/patient/fichier/${id}`)
}

function getPatientDevisByPatientIdAndId(id: string, devisId: string): Promise<File|null> {
    return getFileOrNull.value(`/api/patient/${id}/devis/${devisId}`)
}

export function deletePatientDevisByPatientIdAndId(id: string, devisId: string): Promise<void> {
    return del.value(`/api/patient/${id}/devis/${devisId}`)
}

export async function findPatientDevisListByPatientId(id: string): Promise<PatientDevisReadData[]> {
    const devisList = await get.value<PatientDevisReadResource[]>(`/api/patient/${id}/devis`)
    const files = await Promise.all(devisList.map(({ id: devisId }) => getPatientDevisByPatientIdAndId(id, devisId)))
    const devisWithFiles = devisList
        .map((devis, idx) => ({ ...devis, file: files[idx] }))
    return devisWithFiles
        .map((devis, idx) => ({ ...devis, file: files[idx] }))
        .filter(({ file }) => file != null) as PatientDevisReadData[]
}

export function updatePatientById(id: string, data: PatientWriteData): Promise<void> {
    const fichiers = data.dossierMedical.fichiers.toCreate.map(({file}) => file)
    const resource: PatientWriteResource = buildPatientWriteResourceFromPatientWriteData(data)
    const formData = new FormData()

    formData.append("resource", JSON.stringify(resource))
    fichiers.forEach((fichier, idx) => formData.append(`fichier_${idx}`, fichier))

    return putMultipart.value(`/api/patient/${id}`, formData)
}

export function findPartageByPatientId(id: string): Promise<PatientPartageReadData[]> {
    return get.value(`/api/patient/${id}/partage`)
}

export function updatePatientPartagesByPatientId(id: string, partages: PatientPartagesUpdateData): Promise<void> {
    return put.value(`/api/patient/${id}/partage`, partages)
}

export function updatePatientsPartages(data: PatientsPartagesUpdateData): Promise<void> {
    return put.value(`/api/patient/partage`, data)
}

export function duplicatePatientIntoGestionnaireIdById(id: string, gestionnaireDossierId: string): Promise<void> {
    return post.value(`/api/patient/${id}/_duplicate`, { gestionnaireDossierId })
}

export function deletePatientById(id: string): Promise<void> {
    return del.value(`/api/patient/${id}`)
}

export function generateDevisPatientById(id: string, resource: PatientDevisCreateResource): Promise<number> {
    return post.value<DataResource<number>>(`/api/patient/${id}/_generateDevis`, resource)
        .then(({ data}) => data)
}

export function generateAssocieDevisPatientById(id: string, resource: PatientDevisCreateResource, emetteur: AssocieDevisEmetteurType): Promise<number> {
    return post.value<DataResource<number>>(`/api/patient/${id}/_generateDevis`, resource, {'Emetteur': emetteur})
        .then(({ data}) => data)
}

export function searchPaginatedTableHistoriquesByPatientId(id: string, pagination: PaginationCriteria): Promise<SearchResult<TablePatientHistorique>> {
    return getPaginated.value(`/api/patient/${id}/historique`, buildPatientHistoriquePagination(pagination))
}

export function searchPaginatedTablePatientsForRemplacementByPartageUtilisateurId(partageUtilisateurId: string, criteria: PatientRemplacementCriteria): Promise<SearchResult<GerantAssocieTablePatient>> {
    return getPaginated.value(`/api/patient/_searchForRemplacement`, { 
        ...buildParamsFromPatientRemplacementCriteria(criteria),
        gestionnaireDossierId: gestionnaireDossierId.value as string,
        partageUtilisateurId
    })
}

export function updatePatientStatutById(id: string, data: PatientStatutUpdateResource): Promise<void> {
    return put.value(`/api/patient/${id}/statut`, data)
}
export function updatePatientStatutTermineById(id: string, data: PatientStatutTermineUpdateResource): Promise<void> {
    return put.value(`/api/patient/${id}/statut/termine`, data)
}

export function existsPatientByGestionnaireDossierIdNomPrenomDateNaissance(data: PatientExistsData): Promise<boolean> {
    return get.value<DataResource<boolean>>(`/api/patient/_exists`, buildParamsFromPatientExistsData(data))
        .then(({ data }) => data)
}

export function existsPatientByGestionnaireDossierIdNomPrenomDateNaissanceExceptId(data: PatientExistsExceptIdData): Promise<boolean> {
    return get.value<DataResource<boolean>>(`/api/patient/_existsExceptId`, buildParamsFromPatientExistsDataExceptId(data))
        .then(({ data }) => data)
}

function buildParamsFromPatientCriteria(criteria: PatientCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if(criteria.filters.nom) params.nom = criteria.filters.nom
    if(criteria.filters.prenom) params.prenom = criteria.filters.prenom
    if(criteria.filters.codePostal) params.codePostal = criteria.filters.codePostal
    if(criteria.filters.statut) params.statut = criteria.filters.statut
    if(criteria.sort) params.sort = buildSortParam(criteria.sort)
    return params
}

function buildParamsFromPatientRemplacementCriteria(criteria: PatientRemplacementCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if(criteria.filters.nom) params.nom = criteria.filters.nom
    if(criteria.filters.prenom) params.prenom = criteria.filters.prenom
    if(criteria.sort) params.sort = buildSortParam(criteria.sort)
    return params
}

function buildParamsFromGerantAssociePatientCriteria(criteria: GerantAssociePatientCriteria) {
    const params = buildParamsFromPatientCriteria(criteria)
    if (criteria.filters.utilisateurIdOrNone) params.utilisateurIdOrNone = criteria.filters.utilisateurIdOrNone
    return params
}

export const buildPatientWriteResourceFromPatientWriteData = (data: PatientWriteData): PatientWriteResource => ({
    individu: data.individu,
    etat: data.etat,
    dateNaissance: data.dateNaissance,
    datePremierContact: data.datePremierContact,
    email: data.email,
    facturation: data.facturation,
    adresse: data.adresse,
    telephones: data.telephones,
    lieuPriseEnCharges: data.lieuPriseEnCharges,
    dossierMedical: {
        diagnostic: data.dossierMedical.diagnostic,
        difficultes: data.dossierMedical.difficultes,
        medecinReferentContact: data.dossierMedical.medecinReferentContact,
        numeroSecuriteSociale: data.dossierMedical.numeroSecuriteSociale,
        fichiers: {
            toCreate: data.dossierMedical.fichiers.toCreate.map(fichier => ({
                id: fichier.id,
                type: fichier.type,
                nom: fichier.nom
            })),
            toUpdate: data.dossierMedical.fichiers.toUpdate.map(fichier => ({
                id: fichier.id,
                type: fichier.type,
                nom: fichier.nom
            })),
            toDelete: data.dossierMedical.fichiers.toDelete
        }
    }
})

function buildPatientHistoriquePagination(pagination: PaginationCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: pagination.number.toString(),
        pageSize: pagination.size.toString()
    }
    return params
}

function buildParamsFromPatientExistsData(data: PatientExistsData) {
    const params: {[key: string]: string} = {
        nom: data.nom,
        dateNaissance: data.dateNaissance
    }
    if (data.prenom !== null) params.prenom = data.prenom
    return params
}

function buildParamsFromPatientExistsDataExceptId(data: PatientExistsExceptIdData) {
    const params = buildParamsFromPatientExistsData(data)
    params.id = data.id
    return params
}
