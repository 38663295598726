import {computed, ref} from 'vue'
import {parametrageCabinet, perimetre} from '@/services/authentication.service'

export const loading = ref(false)

let timeoutHandler: number|null = null

let loadingCount = 0
export const showLoading = (timeout = 0) => {
    if (timeoutHandler !== null) {
        clearTimeout(timeoutHandler)
    }
    loadingCount++
    timeoutHandler = setTimeout(() => loading.value = true, timeout)
}

export const hideLoading = () => {
    if (loadingCount !== 0) {
        loadingCount--
    }
    if (loadingCount === 0) {
        if (timeoutHandler !== null) {
            clearTimeout(timeoutHandler)
        }
        loading.value = false
    }
}

export const menuItems = computed(() => {
    const perimetreValue = perimetre.value
    const type = perimetreValue?.type

    if (type === null) return []

    return [{
        label: 'Accueil',
        link: '/',
        iconClass: 'fas fa-tachometer-alt',
        displayed: true
    }, {
        label: 'Administration',
        iconClass: 'fa fa-cogs',
        displayed: type === 'SUPER_ADMIN' || type === 'GERANCE' || type === 'ASSOCIATION',
        image: '/img/accueil-administration.jpg',
        link: '/utilisateurs',
        children: [{
            label: 'Paramètres',
            link: '/parametres',
            displayed: type === 'SUPER_ADMIN'
        }, {
            label: 'Utilisateurs',
            link: '/utilisateurs',
            displayed: type === 'SUPER_ADMIN' || type === 'ASSOCIATION' || type === 'GERANCE'
        }, {
            label: 'Cabinets',
            link: '/cabinets',
            displayed: type === 'SUPER_ADMIN'
        }, {
            label: 'Cabinet',
            link: '/cabinet',
            displayed: parametrageCabinet.value
        }]
    }, {
        label: 'Dossiers',
        iconClass: 'fas fa-briefcase-medical',
        displayed: type != 'SUPER_ADMIN',
        image: '/img/accueil-dossier.jpg',
        link: '/patients',
        children: [{
            label: 'Patients',
            link: '/patients',
            displayed: true
        }, {
            label: 'Clients',
            link: '/clients',
            displayed: true
        }]
    }, {
        label: 'Agenda',
        iconClass: 'fas fa-calendar-alt',
        link: '/agenda',
        image: '/img/accueil-agenda.jpg',
        displayed: type !== 'SUPER_ADMIN'
    }, {
        label: 'Evénements',
        iconClass: 'fas fa-user-md',
        link: '/evenements',
        image: '/img/accueil-evenement.jpg',
        displayed: type !== 'SUPER_ADMIN'
    }, {
        label: 'Prestations',
        image: '/img/accueil-facture.jpg',
        link: '/prestations',
        iconClass: 'fas fa-file-lines',
        displayed: type !== 'SUPER_ADMIN'
    }, {
        label: 'Facturation',
        iconClass: 'fas fa-credit-card',
        displayed: type != 'SUPER_ADMIN',
        image: '/img/accueil-facture.jpg',
        link: '/factures',
        children: [{
            label: 'Factures',
            link: '/factures',
            displayed: true
        }, {
            label: 'Factures d\'avoir',
            link: '/factures-avoir',
            displayed: type !== 'VACATARIAT'
        }]
    }, {
        label: 'Statistiques',
        iconClass: 'fa-sharp fa-solid fa-chart-area',
        displayed: type != 'SUPER_ADMIN',
        image: '/img/accueil-stat.jpg',
        link: '/statistiques/patients',
        children: [{
            label: 'Patients',
            link: '/statistiques/patients',
            displayed: true
        }, {
            label: 'Chiffre d\'affaire',
            link: '/statistiques/chiffre-affaires',
            displayed: true
        }, {
            label: 'Evenements par statut',
            link: '/statistiques/evenements',
            displayed: true
        }, {
            label: 'Occupation du cabinet',
            link: '/statistiques/occupation-cabinet',
            displayed: type === 'GERANCE' || type === 'ASSOCIATION'
        }]
    }]
});

