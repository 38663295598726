

import {defineComponent, ref, watch} from "vue";
import Icon from "@/components/common/icon/Icon.vue";
import ToggleButton from "@/components/common/button/ToggleButton.vue";

export default defineComponent({
  name: 'Editor',
  components: {
    ToggleButton,
    Icon
  },
  props: {
    modelValue: {
      type: String
    }
  },
  setup(props, ctx) {
    const bold = ref(false);
    const italic = ref(false);
    const underline = ref(false);

    const content = ref(props.modelValue);
    let rawContent = content.value;
    watch(() => props.modelValue, value => {
      if(value !== rawContent) {
        content.value = value;
        rawContent = value;
      }
    });

    const editor = ref<HTMLDivElement>()

    const onInput = (innerHTML: string) => {
      rawContent = innerHTML
      ctx.emit('update:model-value', innerHTML);
    }

    const onBold = () => {
      document.execCommand('bold');
    }

    const onItalic = () => {
      document.execCommand('italic');
    }

    const onUnderline = () => {
      document.execCommand('underline');
    }

    const onCaretMove = () => {
      const selection = document.getSelection();
      if(selection) {
        const range = selection.getRangeAt(0)
        let parent = range.endContainer.parentElement;
        let currentBold = false;
        let currentItalic = false;
        let currentUnderline = false;
        do {
          if(parent) {
            if (parent.tagName === 'B') {
              currentBold = true
            }
            if (parent.tagName === 'I') {
              currentItalic = true
            }
            if (parent.tagName === 'U') {
              currentUnderline = true
            }
          }
          parent = parent?.parentElement ?? null
        } while (parent != null && !parent.isEqualNode(editor.value ?? null))
        bold.value = currentBold
        italic.value = currentItalic
        underline.value = currentUnderline
      }
    }

    const preventFocus = (event: Event) => {
      event.preventDefault()
    }

    return {
      content,
      editor,
      italic,
      bold,
      underline,
      onInput,
      onBold,
      onItalic,
      onUnderline,
      onCaretMove,
      preventFocus
    }
  }
})
