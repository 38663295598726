import {ref} from "vue";

export const shown = ref(false)
export const message = ref('')
export const cssClass = ref('')

const defaultDuration = 4000

export function showSuccessToast(m: string, duration = defaultDuration): void {
    message.value = m;
    cssClass.value = 'background-green';
    shown.value = true;
    setTimeout(() => shown.value = false, duration)
}

export function showNeutralToast(m: string, duration = defaultDuration): void {
    message.value = m;
    cssClass.value = 'background-orange';
    shown.value = true;
    setTimeout(() => shown.value = false, duration)
}

export function showFailureToast(m: string, duration = defaultDuration): void {
    message.value = m;
    cssClass.value = 'background-red';
    shown.value = true;
    setTimeout(() => shown.value = false, duration)
}