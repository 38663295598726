import {PaginationCriteria} from '@/model/common'
import {EvenementType} from '@/model/evenement'
import {ReglementMode} from '@/model/reglement'

export type OptionTva = 'APPLICABLE' | 'NON_APPLICABLE_ARTICLE_261_QUATRIEMEMENT_CGI' | 'NON_APPLICABLE_ARTICLE_293B_CGI'
export const optionTvaLabelMap: Record<OptionTva, string> = {
    APPLICABLE: 'TVA applicable',
    NON_APPLICABLE_ARTICLE_261_QUATRIEMEMENT_CGI: 'TVA non applicable selon l’article 261 quatrièmement du CGI',
    NON_APPLICABLE_ARTICLE_293B_CGI: 'TVA non applicable selon l’article 293B du CGI'
}

export type PatientPrestationType = 'BILAN' | 'ACTE' | 'VACATION'
export const patientPrestationTypeLabelMap: Record<PatientPrestationType, string> = {
    BILAN: 'Bilan',
    ACTE: 'Acte',
    VACATION: 'Vacation'
}

export const patientPrestationTypeColorMap: Record<PatientPrestationType, string> = {
    ACTE: '#d6e9c6',
    BILAN: '#c3def1',
    VACATION: '#faebcc'
}

export type ClientDevisPrestationType = 'ACTE'  | 'BILAN' | 'PRESTATION_CLIENT'
export const clientDevisPrestationTypeLabelMap: Record<ClientDevisPrestationType, string> = {
    BILAN: 'Bilan',
    ACTE: 'Acte',
    PRESTATION_CLIENT: 'Prestation client'
}

export type PrestationType = PatientPrestationType | 'PRESTATION_CLIENT' | 'REDEVANCE' | 'SOUS_TRAITANCE_BILAN' | 'RETROCESSION' | 'SOUS_TRAITANCE_VACATION'
export const prestationTypeLabelMap: Record<PrestationType, string> = {
    ...patientPrestationTypeLabelMap,
    PRESTATION_CLIENT: 'Prestation client',
    REDEVANCE: 'Redevance',
    SOUS_TRAITANCE_BILAN: 'Sous-traitance bilan',
    RETROCESSION: 'Rétrocession',
    SOUS_TRAITANCE_VACATION: 'Sous-traitance vacation'
}

export type ActePatientType =
    'REEDUCATION' | 'EVALUATION' | 'REUNION' | 'AMENAGEMENT' | 'VACATION'

export const actePatientTypeLabelMap: Record<ActePatientType, string> = {
    REEDUCATION: 'Rééducation',
    EVALUATION: 'Evaluation',
    REUNION: 'Réunion',
    AMENAGEMENT: 'Aménagement',
    VACATION: 'Vacation'
}

export interface PrestationWriteData {
    type: PrestationType,
    date: string,
    description: string,
    identiteTherapeute: boolean,
    quantite: number,
    montant: number,
    utilisateurId: string,
    cabinetId: string,
    clientId: string | null,
    patientId: string | null,
    typeActe: ActePatientType | null,
    patientLieuPriseEnChargeNom: string | null
    beneficiaire: PrestationPatientBeneficiaireWriteData | null,
}

export interface PrestationUpdateData {
    description: string,
    quantite: number,
    montant: number,
    beneficiaire: PrestationPatientBeneficiaireWriteData | null,
}

export interface PrestationReadData {
    type: PrestationType,
    date: string,
    description: string,
    identiteTherapeute: boolean,
    quantite: number,
    montant: number,
    utilisateurId: string,
    cabinetId: string,
    clientId: string | null,
    patientId: string | null,
    typeActe: ActePatientType | null,
    patientLieuPriseEnChargeNom: string | null
    beneficiaire: PrestationPatientBeneficiaireReadData | null
}

export interface PrestationPatientBeneficiaireWriteData {
    denomination: string,
    complement: string | null
}

export interface PrestationPatientBeneficiaireReadData {
    denomination: string,
    complement: string | null
}

export interface RedevancePrestationCreateData {
    description: string,
    recipiendaireUtilisateurId: string,
    prestationIds: string[]
}

export interface SousTraitancePrestationCreateData {
    description: string,
    prestationIds: string[]
}

export interface SousTraitanceVacationPrestationCreateData {
    prestationIds: string[]
}

export type PrestationStatut = 'EN_ATTENTE' | 'REALISEE' | 'NON_REALISEE' | 'PROFORMA' | 'FACTUREE'
export const prestationStatutLabelMap: Record<PrestationStatut, string> = {
    EN_ATTENTE: 'En attente',
    REALISEE: 'Réalisée',
    NON_REALISEE: 'Non réalisée',
    PROFORMA: 'Facture(s) PROFORMA',
    FACTUREE: 'Facturée'
}

export interface TablePrestation {
    id: string,
    type: PrestationType,
    utilisateurId: string | null,
    cabinetId: string | null,
    prestataireUtilisateurId: string | null,
    prestataireCabinetId: string | null,
    prestataireDenomination: string,
    utilisateurDenomination: string,
    evenementId: string | null,
    evenementTitre: string | null,
    evenementType: EvenementType | null,
    quantite: number,
    montant: number,
    sousTraitanceBilanMontant: number | null,
    sousTraitanceBilanPrestationId: string | null,
    sousTraitanceVacationMontant: number | null,
    sousTraitanceVacationPrestationId: string | null,
    date: string,
    description: string,
    statut: PrestationStatut,
    patientId: string | null,
    clientId: string | null,
    recipiendaireUtilisateurId: string | null,
    recipiendaireCabinetId: string | null,
    recipiendaireDenomination: string,
    beneficiaireDenomination: string | null,
    reglements: TablePrestationReglement[]
}

export interface TableRedevancePrestation {
    id: string,
    type: PrestationType,
    quantite: number,
    montant: number,
    redevanceMontant: number | null,
    date: string,
    description: string,
    recipiendaireDenomination: string
}

export interface PrestationCriteriaFilters {
    recipiendaireDenomination: string | null
    dateBefore: string | null,
    dateAfter: string | null,
    currentMonth: boolean,
    description: string | null,
    statut: PrestationStatut | null,
    utilisateurDenomination: string | null,
    prestataireDenomination: string | null
}

export interface PrestationCriteria {
    pagination: PaginationCriteria,
    filters: PrestationCriteriaFilters
}

export interface SousTraitancePrestationCriteriaFilters {
    dateAfter: string | null,
    dateBefore: string | null
}

export interface SousTraitancePrestationCriteria {
    filters: SousTraitancePrestationCriteriaFilters,
    pagination: PaginationCriteria
}

export interface SousTraitanceVacationPrestationCriteriaFilters {
    dateAfter: string | null,
    dateBefore: string | null
}

export interface SousTraitanceVacationPrestationCriteria {
    filters: SousTraitanceVacationPrestationCriteriaFilters,
    pagination: PaginationCriteria
}

export interface TablePrestationReglement {
    id: string,
    montant: number,
    mode: ReglementMode,
    date: string,
    description: string | null
}


export interface TableRetrocessionPrestation {
    id: string,
    quantite: number,
    montant: number,
    pourcentageRetrocession: number,
    date: string,
    description: string,
    patient: string
}

export interface RetrocessionPrestationCriteriaFilters {
    dateAfter: string | null,
    dateBefore: string | null
}

export interface RetrocessionPrestationCriteria {
    filters: RetrocessionPrestationCriteriaFilters,
    pagination: PaginationCriteria
}

export interface RetrocessionPrestationCreateData {
    description: string,
    recipiendaireUtilisateurId: string | null,
    recipiendaireCabinetId: string | null,
    prestationIds: string[]
}

export interface PrestationSaisieSousTraitanceBilanMontantData {
    quantite: number,
    montant: number,
    utilisateur: string
}

export interface PrestationSaisieSousTraitanceVacationMontantData {
    quantite: number,
    montant: number,
    utilisateur: string
}
