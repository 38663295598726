import {
    AgendaEvenementCriteria, AssocieBilanEvenementDenouementData, AssocieBilanPrestationRdvEvenementDenouementData,
    BilanEvenementDenouementData,
    BilanEvenementReadData,
    BilanEvenementReservationAnnulationReadData,
    BilanEvenementReservationAnnulationWriteData,
    BilanEvenementReservationConfirmationReadData,
    BilanEvenementReservationConfirmationWriteData,
    BilanEvenementReservationCreateData,
    BilanEvenementReservationReadData,
    BilanEvenementReservationRefusReadData,
    BilanEvenementReservationRefusWriteData,
    BilanEvenementWriteData,
    BilanPrestationRdvEvenementDenouementData,
    CabinetEvenenementAcceptData,
    CongesAbsenceCriteria, CongesAbsenceExistsData,
    EvenementDuplicationData,
    EvenementExistsData,
    EvenementReadData,
    EvenementWriteData,
    EvenenementAcceptData,
    PaginatedEvenementCriteria,
    RdvEvenementReadData,
    RdvEvenementWriteData,
    ReservationBilanEvenementCriteria,
    TableCongesAbsence,
    TableEvenement,
    TableReservationBilanEvenement
} from '@/model/evenement'
import {del, get, getPaginated, post, postMultipart, put} from '@/services/http.service'
import {DataResource, SearchResult} from '@/model/common'
import {PatientCompteRenduData} from '@/model/dossier/patient'

export function searchTablePaginatedEvenements(criteria: PaginatedEvenementCriteria): Promise<SearchResult<TableEvenement>> {
    return getPaginated.value('/api/evenement/_searchPaginated', buildParamsFromPaginatedEvenementCriteria(criteria))
}

export function searchTableCongesAbsencesByUtilisateurId(utilisateurId: string, criteria: CongesAbsenceCriteria): Promise<SearchResult<TableCongesAbsence>> {
    return getPaginated.value('/api/evenement/conges-absence/_search', {
        ...buildParamsFromCongesAbsenceCriteria(criteria),
        utilisateurId
    })
}

export function searchCabinetAgendaEvenements(criteria: AgendaEvenementCriteria): Promise<TableEvenement[]> {
    return get.value('/api/evenement/_searchCabinetAgenda', buildParamsFromAgendaEvenementCriteria(criteria))
}

export function searchCabinetMemberAgendaEvenements(utilisateurId: string, criteria: AgendaEvenementCriteria): Promise<TableEvenement[]> {
    return get.value('/api/evenement/_searchCabinetMemberAgenda', {
        ...buildParamsFromAgendaEvenementCriteria(criteria),
        utilisateurId
    })
}

export function searchCabinetCongesAbsencesAgendaEvenements(criteria: AgendaEvenementCriteria): Promise<TableEvenement[]> {
    return get.value('/api/evenement/_searchCabinetCongesAbsencesAgenda', buildParamsFromAgendaEvenementCriteria(criteria))
}

export function searchIndependantAgendaEvenements(criteria: AgendaEvenementCriteria): Promise<TableEvenement[]> {
    return get.value('/api/evenement/_searchIndependantAgenda', buildParamsFromAgendaEvenementCriteria(criteria))
}

export function getEvenementById(id: string): Promise<EvenementReadData> {
    return get.value(`/api/evenement/${id}`)
}

export function getRdvEvenementById(id: string): Promise<RdvEvenementReadData> {
    return get.value(`/api/evenement/rdv/${id}`)
}

export function createRdvEvenement(evenement: RdvEvenementWriteData): Promise<void> {
    return post.value('/api/evenement/rdv', evenement)
}

export function updateRdvEvenementById(id: string, evenement: RdvEvenementWriteData): Promise<void> {
    return put.value(`/api/evenement/rdv/${id}`, evenement)
}

export function updateRdvEvenementAccepteById(id: string, accepte: boolean): Promise<void> {
    return put.value(`/api/evenement/rdv/${id}/accepte`, { accepte })
}

export function updateBilanEvenementAccepteById(id: string, accepte: boolean): Promise<void> {
    return put.value(`/api/evenement/bilan/${id}/accepte`, { accepte })
}

export function acceptCabinetEvenements(data: CabinetEvenenementAcceptData): Promise<void> {
    return post.value('/api/evenement/_acceptCabinet', data)
}

export function saisieDenouementEvenements(data: EvenenementAcceptData): Promise<void> {
    return post.value('/api/evenement/_saisieDenouements', data)
}

export function updateNotBilanPrestationRdvEvenementDenouementById(id: string, survenu: boolean): Promise<void> {
    return put.value(`/api/evenement/rdv/${id}/survenu`, { survenu })
}

export function duplicateRdvEvenenementById(id: string, data: EvenementDuplicationData): Promise<void> {
    return post.value(`/api/evenement/rdv/${id}/_duplicate`, data)
}

export function duplicateBilanEvenenementById(id: string, data: EvenementDuplicationData): Promise<void> {
    return post.value(`/api/evenement/bilan/${id}/_duplicate`, data)
}

export function deleteEvenementById(id: string): Promise<void> {
    return del.value(`/api/evenement/${id}`)
}

export function deleteEvenementsByIds(ids: string[]): Promise<void> {
    return post.value(`/api/evenement/_delete`, { ids })
}

export function getBilanEvenementById(id: string): Promise<BilanEvenementReadData> {
    return get.value(`/api/evenement/bilan/${id}`)
}

export function getBilanEvenementReservationById(id: string): Promise<BilanEvenementReservationReadData> {
    return get.value(`/api/evenement/bilan/${id}/reservation`)
}

export function getBilanEvenementReservationConfirmationDataById(id: string): Promise<BilanEvenementReservationConfirmationReadData> {
    return get.value(`/api/evenement/bilan/${id}/reservation/confirmation`)
}

export function confirmBilanEvenementReservationById(id: string, data: BilanEvenementReservationConfirmationWriteData): Promise<void> {
    return post.value(`/api/evenement/bilan/${id}/reservation/_confirm`, data)
}

export function getBilanEvenementReservationRefusDataById(id: string): Promise<BilanEvenementReservationRefusReadData> {
    return get.value(`/api/evenement/bilan/${id}/reservation/refus`)
}

export function rejectBilanEvenementReservationById(id: string, data: BilanEvenementReservationRefusWriteData): Promise<void> {
    return post.value(`/api/evenement/bilan/${id}/reservation/_reject`, data)
}

export function getBilanEvenementReservationAnnulationDataById(id: string): Promise<BilanEvenementReservationAnnulationReadData> {
    return get.value(`/api/evenement/bilan/${id}/reservation/annulation`)
}

export function cancelBilanEvenementReservationFromAdminById(id: string, data: BilanEvenementReservationAnnulationWriteData): Promise<void> {
    return post.value(`/api/evenement/bilan/${id}/reservation/_cancelFromAdmin`, data)
}

export function cancelBilanEvenementReservationFromPatientById(id: string): Promise<void> {
    return post.value(`/api/evenement/bilan/${id}/reservation/_cancelFromPatient`)
}

export function getEvenementDebutById(id: string): Promise<string> {
    return get.value<DataResource<string>>(`/api/evenement/${id}/debut`)
        .then(({ data }) => data)
}

export function createBilanEvenement(evenement: BilanEvenementWriteData): Promise<void> {
    return post.value('/api/evenement/bilan', evenement)
}

export function updateBilanEvenementById(id: string, evenement: BilanEvenementWriteData): Promise<void> {
    return put.value(`/api/evenement/bilan/${id}`, evenement)
}

export function processBilanEvenementCollaborateurVacataireDenouementById(id: string, data: BilanEvenementDenouementData) {
    return post.value(`/api/evenement/bilan/${id}/_processCollaborateurVacataireDenouement`, data)
}

export function processBilanEvenementAssocieDenouementById(id: string, data: AssocieBilanEvenementDenouementData) {
    return post.value(`/api/evenement/bilan/${id}/_processAssocieDenouement`, data)
}

export function processBilanEvenementDenouementById(id: string, data: BilanEvenementDenouementData) {
    return post.value(`/api/evenement/bilan/${id}/_processDenouement`, data)
}

export function processBilanPrestationRdvEvenementCollaborateurVacataireDenouementById(id: string, data: BilanPrestationRdvEvenementDenouementData) {
    return post.value(`/api/evenement/rdv/${id}/_processBilanPrestationCollaborateurVacataireDenouement`, data)
}

export function processBilanPrestationRdvEvenementAssocieDenouementById(id: string, data: AssocieBilanPrestationRdvEvenementDenouementData) {
    return post.value(`/api/evenement/rdv/${id}/_processBilanPrestationAssocieDenouement`, data)
}

export function processBilanPrestationRdvEvenementDenouementById(id: string, data: BilanPrestationRdvEvenementDenouementData) {
    return post.value(`/api/evenement/rdv/${id}/_processBilanPrestationDenouement`, data)
}

export function searchTableReservationBilanEvenementsByCabinetId(cabinetId: string, criteria: ReservationBilanEvenementCriteria): Promise<TableReservationBilanEvenement[]> {
    return get.value('/api/evenement/_searchForReservation', {
        ...buildParamsFromReservationBilanEvenementCriteria(criteria),
        cabinetId
    })
}

export function searchTableReservationBilanEvenementsByUtilisateurIdAndCabinetNull(utilisateurId: string, criteria: ReservationBilanEvenementCriteria): Promise<TableReservationBilanEvenement[]> {
    return get.value('/api/evenement/_searchForReservation', {
        ...buildParamsFromReservationBilanEvenementCriteria(criteria),
        utilisateurId
    })
}

export function createBilanEvenementReservationById(id: string, reservation: BilanEvenementReservationCreateData): Promise<void> {
    return post.value(`/api/evenement/bilan/${id}/reservation`, reservation)
}

export function uploadBilanEvenementCompteRenduById(id: string, data: PatientCompteRenduData): Promise<void> {
    const formData = new FormData()
    const { compteRendu, ...rest } = data
    formData.append("resource", JSON.stringify(rest))
    formData.append("compteRendu", compteRendu)
    return postMultipart.value(`/api/evenement/bilan/${id}/_uploadCompteRendu`, formData)
}

export function uploadRdvEvenementCompteRenduById(id: string, data: PatientCompteRenduData): Promise<void> {
    const formData = new FormData()
    const { compteRendu, ...rest } = data
    formData.append("resource", JSON.stringify(rest))
    formData.append("compteRendu", compteRendu)
    return postMultipart.value(`/api/evenement/rdv/${id}/_uploadCompteRendu`, formData)
}

export function createCongesAbsenceEvenement(evenement: EvenementWriteData): Promise<void> {
    return post.value('/api/evenement/conges-absence', evenement)
}

export function updateCongesAbsenceEvenementById(id: string, evenement: EvenementWriteData): Promise<void> {
    return put.value(`/api/evenement/conges-absence/${id}`, evenement)
}

export function createReunionEvenement(evenement: EvenementWriteData): Promise<void> {
    return post.value('/api/evenement/reunion', evenement)
}

export function updateReunionEvenementById(id: string, evenement: EvenementWriteData): Promise<void> {
    return put.value(`/api/evenement/reunion/${id}`, evenement)
}

export function existsActiveEvenementByUtilisateurIdOverlappingDates(data: EvenementExistsData): Promise<boolean> {
    return post.value<DataResource<boolean>>('/api/evenement/_existsActive', data)
        .then(({ data }) => data)
}

export function existsCongesAbsenceByUtilisateurIdOverlappingDates(data: CongesAbsenceExistsData): Promise<boolean> {
    return post.value<DataResource<boolean>>('/api/evenement/_existsCongesAbsence', data)
        .then(({ data }) => data)
}

function buildParamsFromPaginatedEvenementCriteria(criteria: PaginatedEvenementCriteria) {
    const params: {[key: string]: string} = {}
    params.pageNumber = criteria.pagination.number.toString()
    params.pageSize = criteria.pagination.size.toString()
    if (criteria.filters.debutAfter) params.debutAfter = criteria.filters.debutAfter
    if (criteria.filters.finBefore) params.finBefore = criteria.filters.finBefore
    if (criteria.filters.type) params.type = criteria.filters.type
    if (criteria.filters.titre) params.titre = criteria.filters.titre
    if (criteria.filters.utilisateurDenomination) params.utilisateurDenomination = criteria.filters.utilisateurDenomination
    if (criteria.filters.patientDenomination) params.patientDenomination = criteria.filters.patientDenomination
    if (criteria.filters.statut) params.statut = criteria.filters.statut
    return params
}

function buildParamsFromAgendaEvenementCriteria(criteria: AgendaEvenementCriteria) {
    const params: {[key: string]: string} = {
        debutAfter: criteria.debutAfter,
        finBefore: criteria.finBefore
    }
    return params
}

function buildParamsFromReservationBilanEvenementCriteria(criteria: ReservationBilanEvenementCriteria) {
    const params: {[key: string]: string} = {
        debutAfter: criteria.debutAfter,
        finBefore: criteria.finBefore
    }
    return params
}

function buildParamsFromCongesAbsenceCriteria(criteria: CongesAbsenceCriteria) {
    const params: {[key: string]: string} = {}
    params.pageNumber = criteria.pagination.number.toString()
    params.pageSize = criteria.pagination.size.toString()
    if (criteria.filters.debutAfter) params.debutAfter = criteria.filters.debutAfter
    if (criteria.filters.finBefore) params.finBefore = criteria.filters.finBefore
    return params
}

export function getEvenementEventType(evenement: TableEvenement): string {
  if (evenement.type === 'RDV' && !evenement.bilanPrestation) {
    if (evenement.statut === 'SURVENU') { // OK
      return 'RDV_SURVENU' // OK
    } else if (evenement.statut === 'ANNULE' || evenement.statut === 'NON_REPONDU_ACCEPTATION' || evenement.statut === 'REFUSE' || evenement.statut === 'NON_SURVENU') { // OK
      return 'RDV_BILAN_ANNULE_NON_SURVENU' // OK
    } else if (evenement.statut === 'ATTENTE_ACCEPTATION') { // OK
      return 'RDV_ATTENTE_ACCEPTATION' // OK
    } else if (evenement.statut === 'PLANIFIE' || evenement.statut === 'EN_COURS' || evenement.statut === 'ATTENTE_DENOUEMENT') { // OK
      return 'RDV_PLANIFIE_ATTENTE_DENOUEMENT'
    }
  } else if (evenement.type === 'BILAN' || evenement.bilanPrestation) {
    if (evenement.statut === 'SURVENU' || evenement.statut === 'PLANIFIE' || evenement.statut === 'RESERVE' || evenement.statut === 'EN_COURS' || evenement.statut === 'ATTENTE_DENOUEMENT' || evenement.statut === 'ATTENTE_SAISIE_CR' || evenement.statut === 'ATTENTE_SAISIE_MONTANT_SOUS_TRAITANCE_BILAN' || evenement.statut === 'ATTENTE_SAISIE_CR_MONTANT_SOUS_TRAITANCE_BILAN') { // OK
      return 'BILAN_RESERVE_ATTENTE_DENOUEMENT_SURVENU' // OK
    } else if (evenement.statut === 'ANNULE_ADMIN' || evenement.statut === 'ANNULE_PATIENT' || evenement.statut === 'REFUSE' || evenement.statut === 'NON_REPONDU_ACCEPTATION' || evenement.statut === 'NON_REPONDU_PATIENT_CABINET' || evenement.statut === 'PASSE' || evenement.statut === 'RESERVATION_REFUSEE' || evenement.statut === 'NON_SURVENU') { // OK
      return 'RDV_BILAN_ANNULE_NON_SURVENU' // OK
    } else if(evenement.statut === 'LIBRE') { // OK
      return 'BILAN_LIBRE' // OK
    } else if(evenement.statut === 'ATTENTE_ACCEPTATION'|| evenement.statut == 'ATTENTE_VALIDATION') { // OK
      return 'BILAN_ATTENTE_ACCEPTATION_ATTENTE_VALIDATION' // OK
    }
  } else if (evenement.type === 'REUNION') { // OK
      return 'REUNION' // OK
  } else if (evenement.type === 'CONGES_ABSENCE') { // OK
      return 'CONGES_ABSENCE' // OK
  }
  return ''
}
