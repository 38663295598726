import PatientList from "@/views/admin/dossier/patient/PatientList.vue";
import PatientCreate from "@/views/admin/dossier/patient/create/PatientCreate.vue";
import PatientUpdate from "@/views/admin/dossier/patient/update/PatientUpdate.vue";
import PatientRead from "@/views/admin/dossier/patient/update/PatientRead.vue";
import {RouteLocationNormalized, RouteRecordRaw} from "vue-router";
import {getPatientWithFilesById} from "@/services/patient.service";
import {handleBeforeEnterHttpErrors} from "@/router/RouteUtils";

const routes: Array<RouteRecordRaw> = [{
    path: 'patients',
    name: 'Patients',
    component: PatientList,
}, {
    path: 'patients/:id/edit',
    name: 'Patients update',
    component: PatientUpdate,
    beforeEnter: handleBeforeEnterHttpErrors(async (to, from, next) => {
        to.meta.patient = await getPatientWithFilesById(to.params.id as string)
        next()
    }),
    props: (route: RouteLocationNormalized) => ({
        id: route.params.id as string,
        patient: route.meta.patient
    })
}, {
    path: 'patients/:id',
    name: 'Patients read',
    component: PatientRead,
    beforeEnter: handleBeforeEnterHttpErrors(async (to, from, next) => {
        to.meta.patient = await getPatientWithFilesById(to.params.id as string)
        next()
    }),
    props: (route: RouteLocationNormalized) => ({
        id: route.params.id as string,
        patient: route.meta.patient
    })
}, {
    path: 'patients/nouveau',
    name: 'Patients create',
    component: PatientCreate
}]

export default routes;
