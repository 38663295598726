import {RouteRecordRaw} from 'vue-router'
import StatistiquesPatients from '@/views/admin/statistiques/patient/StatistiquesPatients.vue'
import StatistiquesChiffreAffaires from '@/views/admin/statistiques/ca/StatistiquesChiffreAffaires.vue'
import StatistiquesEvenementStatut from '@/views/admin/statistiques/evenement/StatistiquesEvenementStatut.vue'
import GerantAssocieStatistiquesOccupationCabinet from '@/views/admin/statistiques/GerantAssocieStatistiquesOccupationCabinet.vue'

const routes = [{
    path: 'statistiques/patients',
    name: 'Statistiques patients',
    component: StatistiquesPatients
}, {
    path: 'statistiques/chiffre-affaires',
    name: 'Statistiques chiffre affaires',
    component: StatistiquesChiffreAffaires,
}, {
    path: 'statistiques/evenements',
    name: 'Statistiques evenement statut',
    component: StatistiquesEvenementStatut,
}, {
    path: 'statistiques/occupation-cabinet',
    name: 'Statistiques occupation cabinet',
    component: GerantAssocieStatistiquesOccupationCabinet,
}] as Array<RouteRecordRaw>;

export default routes;
