import {del, get, post} from './http.service'
import {Authentication, AuthenticationPerimetres, Credentials, PerimetreUtilisateur} from "@/model/authentication";
import {computed, ref, watch} from 'vue'
import {getCookie, removeCookie, setCookie} from "@/services/cookie.service";
import {getAssociationPermissionsById} from '@/services/association.service'

export const auth = ref<Authentication | null>(null);

const _impersonationId = ref<string|null>(getCookie('impersonationId'))
export const impersonationId = computed({
    get() {
        return _impersonationId.value
    },
    set(value: string | null) {
        _impersonationId.value = value
        if(value !== null) {
            setCookie('impersonationId', value)
        } else {
            removeCookie('impersonationId')
        }
    }
})

export const impersonation = ref<Authentication | null>(null)
export const activeAuth = computed<Authentication | null>(() => impersonation.value ?? auth.value)
export const perimetres = computed<Map<string, PerimetreUtilisateur> | null>(() => {
    const activeAuthValue = activeAuth.value
    if (activeAuthValue !== null) {
        return buildPerimetresFromAuthentication(activeAuthValue.perimetres)
    }
    return null
})
export const hasPerimetres = computed(() => {
    const perimetresValue = perimetres.value
    return perimetresValue !== null && perimetresValue.size > 0
})
export const superAdmin = computed(() => {
    const perimetresValue = perimetres.value
    return perimetresValue !== null  && perimetresValue.has('SUPER_ADMIN');
})

const _perimetreId = ref<string|null>(getCookie('perimetreId'))
export const perimetreId = computed({
    get() {
        return _perimetreId.value
    },
    set (value: string|null) {
        _perimetreId.value = value
        if(value !== null) {
            setCookie('perimetreId', value)
        } else {
            removeCookie('perimetreId')
        }
    }
})

export const perimetre = computed<PerimetreUtilisateur | null>(() => {
    const perimetresValue = perimetres.value

    const perimetreIdValue = perimetreId.value
    if (perimetreIdValue === null || perimetresValue === null) {
        return null
    }
    return perimetresValue.get(perimetreIdValue) ?? null
})

export const parametrageCabinet = ref(false)
watch(() => perimetre.value, async perimetre => {
    if (perimetre !== null) {
        switch (perimetre.type) {
            case 'SUPER_ADMIN': parametrageCabinet.value = false; break;
            case 'GERANCE': parametrageCabinet.value = true; break;
            case 'ASSOCIATION': {
                const permissions = await getAssociationPermissionsById(perimetre.associationId)
                parametrageCabinet.value = permissions.parametrageCabinet;
                break;
            }
            case 'COLLABORATION': parametrageCabinet.value = false; break;
            case 'VACATARIAT': parametrageCabinet.value = false; break;
            case 'INDEPENDANT': parametrageCabinet.value = false; break;
        }
    }
})

export const utilisateurId = computed(() => {
    return activeAuth.value?.id ?? null
})

export const gestionnaireDossierId = computed(() => {
    if (perimetre.value === null) return null
    const type = perimetre.value.type
    if (type === null) return null
    if (type === 'INDEPENDANT') return activeAuth.value?.id ?? null
    if (type === 'GERANCE' || type === 'ASSOCIATION' || type === 'COLLABORATION' || type === 'VACATARIAT') return perimetre.value.cabinet.id
    return null
})

export const cabinetId = computed(() => {
    if (perimetre.value === null) return null
    const type = perimetre.value.type
    if (type === 'GERANCE' || type === 'ASSOCIATION' || type === 'COLLABORATION' || type === 'VACATARIAT') return perimetre.value.cabinet.id
    return null
})

export const relationId = computed(() => {
    if (perimetre.value === null) return null
    const type = perimetre.value.type
    if (type === 'GERANCE') return perimetre.value.geranceId
    if (type === 'ASSOCIATION') return perimetre.value.associationId
    if (type === 'COLLABORATION') return perimetre.value.collaborationId
    if (type === 'VACATARIAT') return perimetre.value.vacatariatId
    if (type === 'INDEPENDANT') return utilisateurId.value
    throw new Error('Not allowed')
})

export const entiteId = computed<string|null>(() => {
    if (perimetre.value === null) return null
    const type = perimetre.value.type
    if (type === 'GERANCE') return perimetre.value.cabinet.id as string
    if (type === 'ASSOCIATION') return perimetre.value.associationId
    if (type === 'COLLABORATION') return perimetre.value.collaborationId
    if (type === 'VACATARIAT') return perimetre.value.vacatariatId
    if (type === 'INDEPENDANT') return utilisateurId.value as string
    throw new Error('Not allowed')
})

export const etablissementId = computed<string|null>(() => {
    if (perimetre.value === null) return null
    const type = perimetre.value.type
    if (type === 'GERANCE') return perimetre.value.etablissementId
    if (type === 'ASSOCIATION') return perimetre.value.etablissementId
    if (type === 'COLLABORATION') return perimetre.value.etablissementId
    if (type === 'VACATARIAT') return perimetre.value.etablissementId
    if (type === 'INDEPENDANT') return perimetre.value.etablissementId
    throw new Error('Not allowed')
})

export async function authenticate(credentials: Credentials): Promise<void> {
    await post.value<void>('/api/authenticate', credentials)
    auth.value = await fetchAuthentication()
    perimetreId.value = getDefaultPerimetreId(perimetres.value);
}

export async function impersonate(id: string): Promise<void> {
    impersonationId.value = id
    impersonation.value = await getAuthenticationByUtilisateurId(id)
    const perimetreIdValue = perimetreId.value
    if (perimetreIdValue === null || !perimetres.value?.has(perimetreIdValue)) {
        perimetreId.value = getDefaultPerimetreId(perimetres.value);
    }
}

export async function cancelImpersonation(): Promise<void> {
    impersonationId.value = null
    impersonation.value = null
    perimetreId.value = getDefaultPerimetreId(perimetres.value);
}

export async function updateAuthentication(): Promise<void> {
    const activeAuthValue = activeAuth.value
    if(activeAuthValue !== null) {
        if (impersonation.value != null) {
            impersonation.value = await getAuthenticationByUtilisateurId(activeAuthValue.id)
        } else {
            auth.value = await getAuthenticationByUtilisateurId(activeAuthValue.id)
        }
    }
}

export async function unauthenticate(): Promise<void> {
    await del.value<void>('/api/authentication')
    auth.value = null
    impersonationId.value = null
    impersonation.value = null
    perimetreId.value = null
}

export function fetchAuthentication(): Promise<Authentication> {
    return get.value<Authentication>('/api/authentication')
}

export function getAuthenticationByUtilisateurId(utilisateurId: string): Promise<Authentication> {
    return get.value<Authentication>(`/api/utilisateur/${utilisateurId}/authentication`)
}

export async function isAuthenticated(): Promise<boolean> {
    if(auth.value === null) {
        try {
            auth.value = await fetchAuthentication();
        } catch (e) {
            return false
        }
    }
    const impersonationIdValue = impersonationId.value
    if (impersonationIdValue !== null) {
        impersonationId.value = impersonationIdValue
        impersonation.value = await getAuthenticationByUtilisateurId(impersonationIdValue)
    }
    const perimetreIdValue = perimetreId.value
    if (perimetreIdValue === null || !perimetres.value?.has(perimetreIdValue)) {
        perimetreId.value = getDefaultPerimetreId(perimetres.value);
    }
    return true;
}

function buildPerimetresFromAuthentication(perimetres: AuthenticationPerimetres): Map<string, PerimetreUtilisateur> {
    const perimetresMap: Map<string, PerimetreUtilisateur> = new Map<string, PerimetreUtilisateur>()

    if (perimetres.superAdmin) {
        const id = 'SUPER_ADMIN'
        perimetresMap.set(id, {
            id,
            type: 'SUPER_ADMIN'
        })
    }

    if (perimetres.independantPerimetre !== null) {
        const id = 'INDEPENDANT'
        perimetresMap.set(id, {
            id,
            type: 'INDEPENDANT',
            ...perimetres.independantPerimetre
        })
    }

    perimetres.gerancePerimetres.forEach(gerancePerimetre => {
        const id = `GERANCE-${gerancePerimetre.cabinet.id}`
        perimetresMap.set(id, {
            id,
            type: 'GERANCE',
            ...gerancePerimetre
        });
    })

    perimetres.associationPerimetres.forEach(associationPerimetre => {
        const id = `ASSOCIATION-${associationPerimetre.cabinet.id}`
        perimetresMap.set(id, {
            id,
            type: 'ASSOCIATION',
            ...associationPerimetre
        });
    })

    perimetres.collaborationPerimetres.forEach(collaborationPerimetre => {
        const id = `COLLABORATION-${collaborationPerimetre.cabinet.id}`
        perimetresMap.set(id, {
            id,
            type: 'COLLABORATION',
            ...collaborationPerimetre
        });
    })

    perimetres.vacatariatPerimetres.forEach(vacatariatPerimetre => {
        const id = `VACATARIAT-${vacatariatPerimetre.cabinet.id}`
        perimetresMap.set(id, {
            id,
            type: 'VACATARIAT',
            ...vacatariatPerimetre
        });
    })

    return perimetresMap
}

function getDefaultPerimetreId(perimetres: Map<string, PerimetreUtilisateur> | null): string | null {
    if(perimetres === null) {
        return null
    }
    let independant = false;
    let gerancePerimetreId: string | null = null;
    let associationPerimetreId: string | null = null;
    let collaborationPerimetreId: string | null = null;
    let vacatariatPerimetreId: string | null = null;
    for (const [idx, perimetre] of perimetres.entries()) {
        if (perimetre.type === 'SUPER_ADMIN') return 'SUPER_ADMIN'
        if (perimetre.type === 'INDEPENDANT') independant = true
        else if (perimetre.type === 'GERANCE') gerancePerimetreId ??= idx
        else if (perimetre.type === 'ASSOCIATION') associationPerimetreId ??= idx
        else if (perimetre.type === 'COLLABORATION') collaborationPerimetreId ??= idx
        else if (perimetre.type === 'VACATARIAT') vacatariatPerimetreId ??= idx
    }
    return (independant ? 'INDEPENDANT' : null) ?? gerancePerimetreId ?? associationPerimetreId ?? collaborationPerimetreId ?? vacatariatPerimetreId ?? null
}


