export function pick<T extends { [key: string]: any }>(t: T, keys: Array<keyof T>): Partial<T> {
    return keys.reduce((acc, cur) => ({...acc, [cur]: t[cur]}), {})
}

export function range(size: number, start = 0): number[] {
    return [...Array(size).keys()].map(i => i + start)
}

export function capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export function groupBy<T, U>(values: T[], fn: (t: T) => U): Map<U, T[]> {
    const map = new Map<U, T[]>()
    values.forEach(value => {
        const u = fn(value)
        map.set(u, [...(map.get(u) ?? []), value])
    })
    return map
}

export function debounce(fn: (...args: any[]) => void, timeout: number): (...args: unknown[]) => void {
    let timer: number
    return (...args: unknown[]): void => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            fn(...args)
        }, timeout)
    }
}

export function mergeArrays<T>(arr: T[][]) {
    return arr.reduce((merged, array) => {
        let existing = merged.filter((subArray) => subArray.filter((subItem) => array.includes(subItem)).length)

        if (!existing.length) {
            existing = [[]]
            merged.push(existing[0])
        } else {
            existing.slice(1).forEach((furtherArray) => {
                furtherArray.forEach((item) => {
                    if (!existing[0].includes(item)) {
                        existing[0].push(item)
                    }
                })
                merged.splice(merged.findIndex((subArray) => furtherArray == subArray), 1)
            })
        }

        array.forEach((item) => {
            if (!existing[0].includes(item)) {
                existing[0].push(item)
            }
        })

        return merged
    }, [] as T[][])
}