import {RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import FactureList from '@/views/admin/facture/FactureList.vue'
import PatientFactureDetails from '@/views/admin/facture/details/PatientFactureDetails.vue'
import ClientFactureDetails from '@/views/admin/facture/details/ClientFactureDetails.vue'
import UtilisateurFactureDetails from '@/views/admin/facture/details/UtilisateurFactureDetails.vue'
import CabinetFactureDetails from '@/views/admin/facture/details/CabinetFactureDetails.vue'
import FactureLibreCreate from '@/views/admin/facture/details/FactureLibreCreate.vue'

const routes = [
    {
        path: '/factures',
        name: 'Factures',
        component: FactureList
    }, {
        path: '/factures/patient/:id',
        name: 'Factures patient details',
        component: PatientFactureDetails,
        props: (route: RouteLocationNormalized) => ({
            id: route.params.id,
        })
    }, {
        path: '/factures/facture-libre',
        name: 'Facture libre',
        component: FactureLibreCreate,
    }, {
        path: '/factures/client/:id',
        name: 'Factures client details',
        component: ClientFactureDetails,
        props: (route: RouteLocationNormalized) => ({
            id: route.params.id,
        })
    }, {
        path: '/factures/utilisateur/:id',
        name: 'Factures utilisateur details',
        component: UtilisateurFactureDetails,
        props: (route: RouteLocationNormalized) => ({
            id: route.params.id,
        })
    }, {
        path: '/factures/cabinet/:id',
        name: 'Factures cabinet details',
        component: CabinetFactureDetails,
        props: (route: RouteLocationNormalized) => ({
            id: route.params.id,
        })
    }
] as Array<RouteRecordRaw>;

export default routes;
