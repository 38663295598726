import {RouteLocationNormalized} from "vue-router";
import ClientList from "@/views/admin/dossier/client/ClientList.vue";
import {getClientById} from "@/services/client.service";
import ClientUpdate from "@/views/admin/dossier/client/update/ClientUpdate.vue";
import {handleBeforeEnterHttpErrors} from "@/router/RouteUtils";
import ClientCreate from "@/views/admin/dossier/client/create/ClientCreate.vue";
import ClientRead from "@/views/admin/dossier/client/read/ClientRead.vue";

const routes = [{
    path: 'clients',
    name: 'Clients',
    component: ClientList,
}, {
    path: 'clients/:id/edit',
    name: 'Clients update',
    component: ClientUpdate,
    beforeEnter: handleBeforeEnterHttpErrors(async (to, from, next) => {
        to.meta.client = await getClientById(to.params.id as string)
        next()
    }),
    props: (route: RouteLocationNormalized) => ({
        id: route.params.id as string,
        client: route.meta.client
    })
}, {
    path: 'clients/:id',
    name: 'Clients read',
    component: ClientRead,
    beforeEnter: handleBeforeEnterHttpErrors(async (to, from, next) => {
        to.meta.client = await getClientById(to.params.id as string)
        next()
    }),
    props: (route: RouteLocationNormalized) => ({
        id: route.params.id as string,
        client: route.meta.client
    })
}, {
    path: 'clients/nouveau',
    name: 'Clients create',
    component: ClientCreate
}]

export default routes;
