import {del, get, post, put} from '@/services/http.service'
import {GeranceCreateData, GerancePermissions, GeranceReadData, GeranceUpdateData} from '@/model/gerance'
import {UtilisateurTableGerance} from '@/model/utilisateur'

export function findUtilisateurGerancesByUtilisateurId(utilisateurId: string): Promise<UtilisateurTableGerance[]> {
    return get.value('/api/gerance', {utilisateurId})
}

export function getGeranceById(id: string): Promise<GeranceReadData> {
    return get.value(`/api/gerance/${id}`)
}

export function createGerance(gerance: GeranceCreateData): Promise<void> {
    return post.value(`/api/gerance`, gerance)
}

export function updateGeranceById(id: string, gerance: GeranceUpdateData): Promise<void> {
    return put.value(`/api/gerance/${id}`, gerance)
}

export function deleteGeranceById(id: string): Promise<void> {
    return del.value(`/api/gerance/${id}`)
}

export async function getGerancePermissionsById(id: string): Promise<GerancePermissions> {
    return get.value(`/api/gerance/${id}/permissions`)
}
