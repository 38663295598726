import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown-trigger-wrapper flex align-items-center" }
const _hoisted_2 = { class: "dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "trigger",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      _renderSlot(_ctx.$slots, "trigger")
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "dropdown")
    ], 512), [
      [_vShow, _ctx.shown]
    ])
  ]))
}