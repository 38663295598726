<template>
  <th class="border-solid-1 border-light-gray padding-0.5">
    <slot></slot>
  </th>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
</style>
