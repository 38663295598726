
import {defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
  name: 'Dropdown',
  setup() {
    const shown = ref(false);
    const toggle = (event: Event) => {
      event.stopPropagation();
      shown.value = !shown.value
    }

    onMounted(() => {
      document.querySelector('body')?.addEventListener('click', () => {
        if (shown.value) {
          shown.value = false;
        }
      })
    })

    return {
      shown,
      toggle
    }
  }
})

