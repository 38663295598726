import {RouteRecordRaw} from "vue-router";
import PrestationList from "@/views/admin/prestation/PrestationList.vue";

const routes = [
  {
    path: '/prestations',
    name: 'Prestations',
    component: PrestationList,
  }
] as Array<RouteRecordRaw>;

export default routes;
