import {computed, ComputedRef, reactive, ref, UnwrapRef} from 'vue'
import {Adresse} from '@/model/common'
import {isBefore} from 'date-fns'
import {parseDate} from '@/utils/DateUtils'

export interface AbstractReactiveForm<VALUE> {
    value: VALUE;
    valid: boolean;
}

export interface ReactiveFormControl<VALUE> extends AbstractReactiveForm<VALUE> {
    error: string | null;
}

export interface ReactiveFormGroup extends AbstractReactiveForm<Record<string, any>> {
    controls: Record<string, AbstractReactiveForm<unknown>>
    error: string;
}

export interface ReactiveFormArray extends AbstractReactiveForm<any[]> {
    controls: Array<AbstractReactiveForm<unknown>>;
    error: string;
}

export function formControl<VALUE>(value: VALUE, validators: Validator<VALUE>[] = []): ReactiveFormControl<VALUE> {
    const valueRef = ref(value)
    const errorRef = computed(() => validators.map(validator => validator(valueRef.value as VALUE)).find(error => error != null) ?? null)
    const validRef = computed(() => errorRef.value === null)
    return reactive({
        value: valueRef,
        error: errorRef,
        valid: validRef
    }) as ReactiveFormControl<VALUE>
}

export function formGroup<T>(controls: Record<string, AbstractReactiveForm<unknown>>, validators: Validator<T>[] = []): ReactiveFormGroup {
    const _controls = reactive(controls) as Record<string, AbstractReactiveForm<unknown>>
    const valueRef = computed(() => Object.fromEntries(Object.entries(_controls).map(([key, abstractForm]) => [key, abstractForm.value]))) as unknown as ComputedRef<T>
    const errorsRef = computed(() => validators.map(validator => validator(valueRef.value)).filter(error => error !== null))
    const errorRef = computed(() => validators.map(validator => validator(valueRef.value)).find(error => error != null) ?? null)
    const validRef = computed(() => Object.values(controls).every(abstractForm => abstractForm.valid) && errorsRef.value.length === 0)
    return reactive({
        value: valueRef as UnwrapRef<unknown>,
        controls: _controls,
        error: errorRef,
        valid: validRef
    }) as ReactiveFormGroup
}

export function formArray(controls: AbstractReactiveForm<unknown>[], validators: Validator<unknown>[] = []): ReactiveFormArray {
    const _controls = reactive(controls);
    const valueRef = computed(() => _controls.map(abstractForm => abstractForm.value)) as ComputedRef<unknown[]>
    const errorsRef = computed(() => validators.map(validator => validator(valueRef.value)).filter(error => error !== null))
    const errorRef = computed(() => validators.map(validator => validator(valueRef.value)).find(error => error != null) ?? null)
    const validRef = computed(() => _controls.every(abstractForm => abstractForm.valid) && errorsRef.value.length === 0)
    return reactive({
        value: valueRef as unknown as UnwrapRef<unknown[]>,
        controls: _controls,
        error: errorRef,
        valid: validRef
    }) as ReactiveFormArray
}

export type Validator<VALUE> = (value: VALUE) => string | null

function isNull<T>(t: T | null | undefined): boolean {
    return t == null
}

function isEmpty(t: string | unknown[] | null | undefined) {
    if (Array.isArray(t)) {
        return t.length === 0;
    }
    return isNull(t) || t?.trim() === ''
}

function getErrorIf(error: string, condition: boolean): string | null {
    return condition ? error : null
}

export const Validators = {
    required<T>(error = 'Champ requis.'): (t: T) => null | string {
        return (t: T) => getErrorIf(error, isNull(t))
    },
    notEmpty(error = 'Champ requis.'): (t: string | unknown[] | null | undefined) => null | string {
        return (t: string | null | unknown[] | undefined) => getErrorIf(error, Array.isArray(t) && t.length === 0 || isEmpty(t))
    },
    email(error = 'Adresse email invalide.'): (t: string|null) => null | string {
        return (t: string|null) => getErrorIf(error, !isEmpty(t) && !/\S+@\S+\.\S+/.test(t as string));
    },
    adresse(error = 'Adresse incomplete.'): (a: Adresse) => null | string {
        return (a: Adresse | null) => {
            if (isNull(a)) {
                return null
            }
            const fieldsEmpty = [isEmpty(a?.rue), isEmpty(a?.codePostal), isEmpty(a?.ville)]
            return getErrorIf(error, !fieldsEmpty.every(empty => empty) && !fieldsEmpty.every(empty => !empty))
        };
    },
    adresseRequired(error = 'Adresse requise.'): (a: Adresse) => null | string {
        return (a: Adresse | null) => getErrorIf(error, isEmpty(a?.rue) || isEmpty(a?.ville) || isEmpty(a?.codePostal))
    },
    password(error = "Le mot de passe doit être composé d'au moins 8 caractères et contenir au moins 1 chiffre, une lettre minuscule et une lettre majuscule."): Validator<string|null> {
        return (p: string | null) => getErrorIf(error, !isEmpty(p) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(p as string))
    },
    positive(error = 'Le nombre renseigné doit être positif'): (n: number | null) => null | string {
        return (n: number | null) => getErrorIf(error, n !== null && n <= 0)
    },
    minDate(min: string, error: string): (date: string | null) => null | string {
        return (d: string | null) => getErrorIf(error, d !== null && isBefore(parseDate(d), parseDate(min)))
    }
}
