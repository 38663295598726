
import {computed, defineComponent, ref, watch} from 'vue';

let instances = ref(0)

export default defineComponent({
  name: 'Dialog',
  props: {
    shown: Boolean,
    witdh: String
  },
  setup(props) {
    watch(() => props.shown, value => {
      if (value)
        instances.value++
      else
        instances.value--
    })
    const zIndex = computed(() => 2000 + (instances.value * 2))

    return {
      zIndex
    }
  }
})
