
import Row from '@/components/layout/Row.vue'
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Navigation',
  components: {
    Row
  },
  props: {
    title: String,
    subtitle: String
  }
})
