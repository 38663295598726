

import {defineComponent} from "vue";

export default defineComponent({
  name: 'Hint',
  props: {
    theme: {
      type: String,
      default: ''
    }
  }
})
