import {Adresse, PaginationCriteria} from '@/model/common'
import {OptionTva} from '@/model/prestation'

export type AvoirFactureStatut = 'PROFORMA' | 'EDITEE' | 'AFFECTEE' | 'SOLDEE'
export const avoirFactureStatutLabelMap: Record<AvoirFactureStatut, string> = {
    PROFORMA: 'PROFORMA',
    EDITEE: 'Editée',
    AFFECTEE: 'Affectée',
    SOLDEE: 'Soldée'
}

export interface AvoirFactureCriteria {
    pagination: PaginationCriteria,
    filters: AvoirFactureCriteriaFilters
}

export interface AvoirFactureCriteriaFilters {
    debutAfter: string | null,
    finBefore: string | null,
    destinataire: string | null,
    statut: AvoirFactureStatut | null
}

export interface AvoirFactureCreateData {
    avoirs: AvoirCreateData[]
}

export interface AvoirCreateData {
    prestationId: string,
    description: string,
    montant: number,
}

export interface TableAvoirFacture {
    id: string,
    createur: string,
    date: string,
    numero: string | null,
    utilisateurId: string | null,
    cabinetId: string | null,
    emetteur: string,
    destinataireDenomination: string,
    total: number,
    statut: AvoirFactureStatut,
    dateDernierEnvoi: string | null,
    prestataireUtilisateurId: string | null,
    prestataireCabinetId: string | null
}

export interface AvoirFactureSendReadData {
    gestionnaireDossierDenomination: string,
    avoirFactureNumero: string,
    avoirFactureDate: string,
    avoirFactureMontant: number
}

export interface AvoirFactureSendWriteData {
    destinataires: string[],
    carbonCopies: string[],
    subject: string,
    content: string
}


export interface AvoirFactureGenerateModel {
    emetteur: AvoirFactureGenerateEmetteurModel,
    destinataire: AvoirFactureGenerateDestinataireModel,
    avoirs: AvoirFactureGenerateAvoirModel[],
    date: string,
    totalHT: number,
    montantTva: number,
    total: number,
    talon: string | null
}

export interface AvoirFactureGenerateEmetteurModel {
    id: string,
    couleur: string | null,
    denomination: string,
    profession: string | null,
    adresse: Adresse,
    adresseReglements: Adresse | null,
    telephone: string,
    email: string,
    siret: string,
    codeApe: string,
    numeroRpps: string | null
}

export interface AvoirFactureGenerateDestinataireModel {
    denomination: string,
    adresse: Adresse
}

export interface AvoirFactureGenerateAvoirModel {
    id: string,
    description: string,
    montant: number,
    optionTva: OptionTva,
    tauxTva: number | null
}

export interface AvoirFactureUpdateData {
    talon: string | null,
    date: string
}

export interface FactureAvoirFactureCriteria {
    pagination: PaginationCriteria,
    filters: FactureAvoirFactureCriteriaFilters
}

export interface FactureAvoirFactureCriteriaFilters {
    debutAfter: string | null,
    finBefore: string | null,
    destinataire: string | null,
    soldeFactureId: string
}

export interface FactureLibreAvoirFactureCriteria {
    pagination: PaginationCriteria,
    filters: FactureLibreAvoirFactureCriteriaFilters
}

export interface FactureLibreAvoirFactureCriteriaFilters {
    debutAfter: string | null,
    finBefore: string | null,
    destinataire: string | null
}
