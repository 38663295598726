import {RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import AvoirFactureList from '@/views/admin/avoir/AvoirFactureList.vue'
import AvoirFactureDetails from '@/views/admin/avoir/details/AvoirFactureDetails.vue'

const routes = [
    {
        path: '/factures-avoir',
        name: 'Factures avoirs',
        component: AvoirFactureList
    }, {
        path: '/factures-avoir/:id',
        name: 'Facture avoir details',
        component: AvoirFactureDetails,
        props: (route: RouteLocationNormalized) => ({
            id: route.params.id,
        })
    }
] as Array<RouteRecordRaw>;

export default routes;
