import {RouteRecordRaw} from 'vue-router'
import UtilisateurList from "@/views/admin/utilisateur/UtilisateurList.vue";

const routes = [
  {
    path: 'utilisateurs',
    name: 'Utilisateurs',
    component: UtilisateurList,
  }
] as Array<RouteRecordRaw>;


export default routes;
