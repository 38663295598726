
import {computed, defineComponent} from 'vue';
export default defineComponent({
  name: 'Option',
  props: {
    value: [String, Number, Boolean],
    selected: Boolean,
    disabled: Boolean
  },
  setup(props) {
    const val = computed(() => props.value === null ? '' : props.value)

    return {
      val
    }
  }
})
