export function setCookie(name: string, value: string) {
    document.cookie = `${name}=${value}`
}

export function getCookie(name: string): string | null {
    const cookieString = document.cookie
    if (!cookieString) return null
    const cookies = new Map<string, string>(cookieString
        .split(";")
        .map(cookie => cookie.trim())
        .map(cookie => cookie.split('=', 2) as [string, string]
    ));
    return cookies.get(name) ?? null
}

export function removeCookie(name: string) {
    document.cookie=`${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`
}

export function setJsonCookie(name: string, value: unknown) {
    setCookie(name, JSON.stringify(value))
}

export function getJsonCookie<T>(name: string): T | null {
    const value = getCookie(name)
    if(value === null) {
        return null
    }
    return JSON.parse(value) as T
}