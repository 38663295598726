import {buildSortParam, del, get, getPaginated, post, put} from '@/services/http.service'
import {
    CabinetLabelCollaborateur,
    CabinetLabelUtilisateur,
    GerantAssocieTableUtilisateur,
    RdvEvenementCabinetUtilisateurReadData,
    RdvEvenementUtilisateurReadData,
    SelectUtilisateur,
    SelfUtilisateurReadResource,
    SelfUtilisateurUpdateResource,
    SuperAdminTableUtilisateur,
    UtilisateurCreateResource,
    UtilisateurCriteria,
    UtilisateurReadResource,
    UtilisateurUpdateResource,
} from '@/model/utilisateur'
import {DataResource, SearchResult} from '@/model/common'
import {activeAuth, cabinetId} from '@/services/authentication.service'

export function searchSuperAdminTableUtilisateur(criteria: UtilisateurCriteria): Promise<SearchResult<SuperAdminTableUtilisateur>> {
    return getPaginated.value<SuperAdminTableUtilisateur>('/api/utilisateur/_search', buildParamsFromUtilisateurCriteria(criteria))
}

export function searchOwnGerantAssocieTableUtilisateur(criteria: UtilisateurCriteria): Promise<SearchResult<GerantAssocieTableUtilisateur>> {
    const id = cabinetId.value
    if (id === null) throw new Error('User does not have a cabinetId')
    return getPaginated.value<GerantAssocieTableUtilisateur>('/api/utilisateur/_search', {
        ...buildParamsFromUtilisateurCriteria(criteria),
        cabinetId: id
    })
}

export function findCabinetUtilisateursByCabinetId(cabinetId: string): Promise<CabinetLabelUtilisateur[]> {
    return get.value('/api/utilisateur', { cabinetId }, {'Representation': 'Cabinet'})
}

export function findCabinetCollaborateursByCabinetId(cabinetId: string): Promise<CabinetLabelCollaborateur[]> {
    return get.value('/api/utilisateur', { cabinetId }, {'Representation': 'Collaborateur'})
}

export function getRdvEvenementCabinetUtilisateurByIdAndDate(id: string, date: string): Promise<RdvEvenementCabinetUtilisateurReadData> {
    return get.value(`/api/utilisateur/${id}`, { date }, {'Representation': 'Rdv-Evenement'})
}

export function getOwnRdvEvenementUtilisateur(): Promise<RdvEvenementUtilisateurReadData> {
    return get.value(`/api/utilisateur`, {}, {'Representation': 'Rdv-Evenement'})
}

export function getSelfUtilisateur(): Promise<SelfUtilisateurReadResource> {
    return get.value(`/api/utilisateur/${activeAuth.value?.id}/self`)
}

export function updateSelfUtilisateur(utilisateur: SelfUtilisateurUpdateResource): Promise<void> {
    return put.value(`/api/utilisateur/${activeAuth.value?.id}/self`, utilisateur)
}

export function getUtilisateurById(id: string): Promise<UtilisateurReadResource> {
    return get.value(`/api/utilisateur/${id}`)
}

export function createUtilisateur(data: UtilisateurCreateResource): Promise<string> {
    return post.value<DataResource<string>>('/api/utilisateur', data)
        .then(({ data }) => data);
}

export function updateUtilisateurById(id: string, data: UtilisateurUpdateResource): Promise<void> {
    return put.value(`/api/utilisateur/${id}`, data)
}

export function updateUtilisateurPasswordById(id: string, password: string): Promise<void> {
    return put.value(`/api/utilisateur/${id}/password`, { password })
}

export function deleteUtilisateurById(id: string): Promise<void> {
    return del.value(`/api/utilisateur/${id}`)
}

export function findAllSelectUtilisateurs(): Promise<SelectUtilisateur[]> {
    return get.value<SelectUtilisateur[]>('/api/utilisateur');
}

export function isUtilisateurPasswordResetTokenValid(token: string): Promise<boolean> {
    return get.value<DataResource<boolean>>(`/api/utilisateur/${token}/_isPasswordResetTokenValid`)
        .then(({ data }) => data)
}

export function resetUtilisateurPassword(token: string, password: string): Promise<void> {
    return post.value(`/api/utilisateur/${token}/_resetUtilisateurPassword`, { password })
}

export function sendUtilisateurPasswordResetEmailById(token: string): Promise<void> {
    return post.value(`/api/utilisateur/${token}/_sendPasswordResetEmail`)
}

function buildParamsFromUtilisateurCriteria(criteria: UtilisateurCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if(criteria.filters.nom) params.nom = criteria.filters.nom
    if(criteria.filters.prenom) params.prenom = criteria.filters.prenom
    if(criteria.filters.actif != null) params.actif = criteria.filters.actif.toString()
    if(criteria.sort) params.sort = buildSortParam(criteria.sort)
    return params
}
