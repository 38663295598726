export interface Pagination {
  size: number;
  total: number;
}

export interface SearchResult<T> {
  data: T[];
  pagination: Pagination;
}

export type SortType = 'ASC' | 'DESC'
export interface Sort {
  column: string,
  type: SortType
}

export type Civilite = 'MONSIEUR' | 'MADAME'
export const civiliteLabelMap: Record<Civilite, string> = {
  MONSIEUR: 'Monsieur',
  MADAME: 'Madame'
}

export interface Adresse {
  id: string | null;
  nom: string | null;
  rue: string;
  codePostal: string;
  ville: string;
  complement?: string
}

export interface DataResource<T> {
  data: T
}

export interface PaginationCriteria {
  number: number,
  size: number
}

export interface LabelData {
  id: string,
  label: string
}

