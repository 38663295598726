import {Adresse, PaginationCriteria} from '@/model/common'
import {OptionTva, TablePrestation, TablePrestationReglement} from '@/model/prestation'
import {Reglement} from '@/model/reglement'

export type ModeReglement = 'CB' | 'VIREMENT' | 'CHEQUE' |  'ESPECES' | 'AVOIR'
export const modeReglementLabelMap: Record<ModeReglement, string> = {
    CB: 'Carte bancaire',
    VIREMENT: 'Virement',
    CHEQUE: 'Chèque',
    ESPECES: 'Espèces',
    AVOIR: 'Avoir'
}

export type FactureStatut = 'PROFORMA' | 'EDITEE';
export const factureStatutLabelMap: Record<FactureStatut, string> = {
    PROFORMA: 'Proforma',
    EDITEE: 'Editée',
}

export type FactureDestinataireType = 'PATIENT' | 'CLIENT' | 'UTILISATEUR' | 'CABINET';
export const factureDestinataireTypeLabelMap: Record<FactureDestinataireType, string> = {
    PATIENT: 'Patient',
    CLIENT: 'Client',
    UTILISATEUR: 'Collaborateur',
    CABINET: 'Cabinet'
}

export interface FactureCriteria {
    filters: FactureCriteriaFilters,
    pagination: PaginationCriteria
}

export interface FactureCriteriaFilters {
    debutAfter: string | null,
    finBefore: string | null,
    statut: FactureStatut | null
    destinataire: string | null,
    numero: string | null,
    soldee: boolean | null
}

export type AssocieFactureCriteriaFiltersPrestataire = 'ASSOCIE' | 'CABINET'

export interface AssocieFactureCriteriaFilters extends FactureCriteriaFilters {
    prestataire: AssocieFactureCriteriaFiltersPrestataire
}

export interface AssocieFactureCriteria {
    filters: AssocieFactureCriteriaFilters,
    pagination: PaginationCriteria
}

export interface TableFacture {
    id: string,
    patientId: string | null,
    clientId: string | null,
    recipiendaireUtilisateurId: string | null,
    recipiendaireCabinetId: string | null,
    createur: string,
    date: string,
    numero: string | null,
    utilisateurId: string | null,
    cabinetId: string | null,
    emetteur: string,
    destinataireDenomination: string,
    destinataireType: FactureDestinataireType | null,
    total: number,
    regle: number,
    solde: number,
    statut: FactureStatut,
    dateDernierEnvoi: string | null,
    prestataireUtilisateurId: string | null,
    prestataireCabinetId: string | null,
    libre: boolean
}

export interface FactureGenerateEmetteurModel {
    id: string,
    couleur: string | null,
    denomination: string,
    profession: string | null,
    adresse: Adresse,
    adresseReglements: Adresse | null,
    telephone: string,
    email: string,
    siret: string,
    codeApe: string,
    numeroRpps: string | null
}

export interface FactureGenerateDestinataireModel {
    denomination: string,
    adresse: Adresse
}

export interface FactureGeneratePatientModel {
    nom: string,
    prenom: string,
    dateNaissance: string
}

export interface FactureGeneratePrestationModel {
    date: string,
    description: string,
    quantite: number,
    montant: number,
    optionTva: OptionTva,
    tauxTva: number | null,
    lieu: string | null,
    beneficiaire: FactureGeneratePrestationBeneficiaireModel | null,
    isPrestation: boolean
}

export interface FactureGenerateAvoirFactureModel {
    id: string,
    date: string,
    avoirs: FactureGenerateAvoirModel[]
}

export interface FactureGenerateAvoirModel {
    id: string,
    description: string,
    date: string,
    montant: number,
    optionTva: OptionTva,
    tauxTva: number | null
}

export interface FactureGeneratePrestationBeneficiaireModel {
    denomination: string,
    complement: string | null
}

export interface FactureGenerateModel {
    emetteur: FactureGenerateEmetteurModel,
    destinataire: FactureGenerateDestinataireModel,
    patient: FactureGeneratePatientModel | null,
    prestations: FactureGeneratePrestationModel[],
    avoirFactures: FactureGenerateAvoirFactureModel[],
    date: string,
    delaiReglement: number | null,
    totalHT: number,
    regle: number,
    montantTva: number,
    prestationsTotalHT: number,
    solde: number,
    talon: string | null,
    siret: string | null
}

export interface FactureReglement {
    beforeEdit: boolean,
    reglement: Reglement
}

export interface FactureUpdateData {
    talon: string | null,
    date: string,
    reglements: FactureReglement[],
    avoirFactureIds: string[]
}

export interface FactureSendReadData {
    factureDestinataireEmail: string | null,
    gestionnaireDossierDenomination: string,
    factureNumero: string,
    factureDate: string,
    factureMontant: number
}

export interface FactureSendWriteData {
    destinataires: string[],
    carbonCopies: string[],
    subject: string,
    content: string
}

export interface AssocieFactureCreateData {
    self: FactureCreateData,
    cabinet: FactureCreateData
}

export interface AssocieFactureCreateResource {
    self: FactureCreateResource,
    cabinet: FactureCreateResource
}

export interface FacturePatientFacturation {
    pourcentage: number,
    entiteFacturables: FacturePatientFacturationEntiteFacturable[]
}

export interface FacturePatientFacturationResource {
    pourcentage: number,
    prestations: {
        id: string,
        reglements: {
            id: string,
            montant: number
        }[]
    }[],
    entiteFacturables: FacturePatientFacturationEntiteFacturableResource[]
}

export interface FacturePatientFacturationEntiteFacturable {
    id: string,
    pourcentage: number,
    denomination: string,
    adresse: Adresse | null
}

export interface FacturePatientFacturationEntiteFacturableResource {
    id: string,
    pourcentage: number,
    denomination: string,
    adresse: Adresse | null,
    prestations: {
        id: string,
        reglements: {
            id: string,
            montant: number
        }[]
    }[]
}

export interface FacturePatientReglement extends TablePrestationReglement {
    assignations: FacturePatientReglementAssignations
}

export interface FacturePatientReglementAssignations {
    patient: {
        denomination: string,
        montant: number
    },
    entitesFacturables: {
        denomination: string,
        montant: number
    }[]
}

export interface FacturePatientPrestation {
    id: string,
    montant: number,
    date: string,
    description: string,
    quantite: number,
    beneficiaireDenomination: string | null,
    prestataireUtilisateurId: string | null,
    reglements: FacturePatientReglement[]
}

export interface FactureCreateData {
    gatheredPrestations: boolean,
    patients: {
        id: string,
        denomination: string,
        facturation: FacturePatientFacturation,
        prestations: FacturePatientPrestation[]
    }[],
    clients: {
        id: string,
        denomination: string,
        prestations: TablePrestation[]
    }[]
    utilisateurs: {
        id: string,
        denomination: string,
        prestations: TablePrestation[]
    }[],
    cabinet: {
        id: string,
        denomination: string,
        prestations: TablePrestation[]
    } | null
}

export interface FactureCreateResource {
    gatheredPrestations: boolean,
    patients: {
        id: string,
        facturation: FacturePatientFacturationResource
    }[],
    clients: {
        id: string,
        prestationIds: string[]
    }[]
    utilisateurs: {
        id: string,
        prestationIds: string[]
    }[],
    cabinet: {
        id: string,
        prestationIds: string[]
    } | null
}

export interface FactureLibreCreateData {
    entiteId: string,
    date: string | null,
    destinataire: FactureLibreDestinataireCreateData,
    prestations: FactureLibrePrestationCreateData[],
    reglements: FactureLibreReglement[],
    avoirFactureIds: string[],
    talon: string | null,
    siret: string | null
}

export interface FactureLibreDestinataireCreateData {
    denomination: string | null,
    adresse: Adresse | null,
}

export interface FactureLibrePrestationCreateData {
    date: string,
    description: string,
    quantite: number,
    montant: number,
    patients: string | null,
    optionTva: OptionTva,
    tauxTva: number | null
}

export interface FactureLibreEntite {
    id: string,
    couleur: string | null,
    denomination: string,
    profession: string | null,
    adresse: Adresse,
    adresseReglements: Adresse | null,
    telephone: string,
    email: string,
    siret: string,
    codeApe: string,
    numeroRpps: string | null,
    depassementFranchiseTva: boolean,
    delaiReglement: number | null,
}

export interface FactureLibreReglement {
    beforeEdit: boolean,
    reglement: Reglement
}

export interface FactureAvoirFactureReadData {
    date: string,
    numero: string,
    prestations: FactureAvoirFacturePrestationReadData[]
}

export interface FactureAvoirFacturePrestationReadData {
    id: string,
    date: string,
    description: string,
    montant: number,
    quantite: number
}