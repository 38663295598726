import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "toast" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!

  return (_ctx.shown)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#app"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["padding-1", _ctx.cssClass])
          }, [
            _createVNode(_component_Label, {
              class: "font-size-1.3 color-white",
              bold: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.message), 1)
              ]),
              _: 1
            })
          ], 2)
        ])
      ]))
    : _createCommentVNode("", true)
}