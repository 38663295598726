import {get} from '@/services/http.service'
import {
    AssocieStatistiquesChiffreAffairesCriteria,
    StatistiquesChiffreAffaires,
    StatistiquesChiffreAffairesCriteria,
    StatistiquesEvenementStatut,
    StatistiquesEvenementStatutCriteria, StatistiquesOccupationCabinet, StatistiquesOccupationCabinetCriteria,
    StatistiquesPatients
} from '@/model/statistiques'

export function findStatistiquesPatients(): Promise<StatistiquesPatients[]> {
    return get.value(`/api/statistiques/patients`)
}

export function findStatistiquesPatientsByAndUtilisateurId(utilisateurId: string): Promise<StatistiquesPatients[]> {
    return get.value(`/api/statistiques/patients`, { utilisateurId })
}

export function getStatistiquesChiffreAffaires(criteria: StatistiquesChiffreAffairesCriteria): Promise<StatistiquesChiffreAffaires[]> {
    return get.value(`/api/statistiques/ca`, buildParamsFromStatistiquesChiffreAffairesCriteria(criteria))
}

export function getAssocieStatistiquesChiffreAffaires(criteria: AssocieStatistiquesChiffreAffairesCriteria): Promise<StatistiquesChiffreAffaires[]> {
    return get.value(`/api/statistiques/ca`, buildParamsFromAssocieStatistiquesChiffreAffairesCriteria(criteria))
}

export function getStatistiquesEvenementStatut(criteria: StatistiquesEvenementStatutCriteria): Promise<StatistiquesEvenementStatut> {
    return get.value(`/api/statistiques/evenement`, buildParamsFromStatistiquesEvenementStatutCriteria(criteria))
}

export function searchStatistiquesOccupationCabinet(criteria: StatistiquesOccupationCabinetCriteria): Promise<StatistiquesOccupationCabinet[]> {
    return get.value(`/api/statistiques/occupation-cabinet`, buildParamsFromStatistiquesOccupationCabinetCriteria(criteria))
}

function buildParamsFromStatistiquesChiffreAffairesCriteria(criteria: StatistiquesChiffreAffairesCriteria) {
    const params: {[key: string]: string} = {}
    if (criteria.dossierId !== null) params.dossierId = criteria.dossierId
    return params
}

function buildParamsFromAssocieStatistiquesChiffreAffairesCriteria(criteria: AssocieStatistiquesChiffreAffairesCriteria) {
    const params = buildParamsFromStatistiquesChiffreAffairesCriteria(criteria)
    params.prestataire = criteria.prestataire
    return params
}


function buildParamsFromStatistiquesEvenementStatutCriteria(criteria: StatistiquesEvenementStatutCriteria) {
    const params: {[key: string]: string} = {}
    if (criteria.debutAfter) params.debutAfter = criteria.debutAfter
    if (criteria.finBefore) params.finBefore = criteria.finBefore
    if (criteria.type) params.type = criteria.type
    if (criteria.patientId) params.patientId = criteria.patientId
    return params
}
function buildParamsFromStatistiquesOccupationCabinetCriteria(criteria: StatistiquesOccupationCabinetCriteria) {
    return {
        debutAfter: criteria.debutAfter,
        finBefore: criteria.finBefore
    }
}
