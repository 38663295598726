import {get, getPaginated} from '@/services/http.service'
import {perimetre, utilisateurId} from '@/services/authentication.service'
import {TableTache, TacheCriteria} from '@/model/tache'
import {DataResource, SearchResult} from '@/model/common'

export function searchCabinetTachesByCabinetId(cabinetId: string, criteria: TacheCriteria): Promise<SearchResult<TableTache>> {
    if (perimetre.value?.type !== 'GERANCE' && perimetre.value?.type !== 'ASSOCIATION' && perimetre.value?.type !== 'SUPER_ADMIN') throw new Error('Not allowed')
    return getPaginated.value('/api/tache/_search', {
        ...buildParamsFromAgendaEvenementCriteria(criteria),
        cabinetId
    })
}

export function getCabinetTachesCountByCabinetId(cabinetId: string): Promise<number> {
    return get.value<DataResource<number>>('/api/tache/_count', { cabinetId })
        .then(({ data }) => data)
}

export function searchOwnUtilisateurTaches(criteria: TacheCriteria): Promise<SearchResult<TableTache>> {
    return getPaginated.value('/api/tache/_search', {
        ...buildParamsFromAgendaEvenementCriteria(criteria),
        utilisateurId: utilisateurId.value as string
    })
}

export function getOwnUtilisateurTachesCount(): Promise<number> {
    return get.value<DataResource<number>>('/api/tache/_count', { utilisateurId: utilisateurId.value as string })
        .then(({ data }) => data)
}

function buildParamsFromAgendaEvenementCriteria(criteria: TacheCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if (criteria.filters.initiateur != null) {
       params.initiateur = criteria.filters.initiateur
    }
    return params
}