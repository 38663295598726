import {format, formatISO, parse, parseISO} from 'date-fns'

export function formatISODateTime(value: Date) {
    return format(value, 'yyyy-MM-dd\'T\'HH:mm:ss')
}

const date = new Date()
export function parseDate(value: string) {
    return parse(value, 'yyyy-MM-dd', date)
}

export function parseAndFormatDate(value: string) {
    return formatISO(parseDate(value), { representation: 'date' })
}

export function formatLabelDateTime(value: string) {
    return format(parseISO(value), 'dd/MM/yyyy - HH\'h\'mm')
}

export function formatLabelDate(value: string | null) {
    if(value === null) return null
    return format(parseISO(value), 'dd/MM/yyyy')
}
