import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ee22ce5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "editor" }
const _hoisted_2 = { class: "editor-buttons" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ToggleButton, {
        class: "background-dynamic-lighter-gray",
        onClick: _ctx.onBold,
        onMousedown: _ctx.preventFocus,
        modelValue: _ctx.bold,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bold) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, { name: "fas fa-bold" })
        ]),
        _: 1
      }, 8, ["onClick", "onMousedown", "modelValue"]),
      _createVNode(_component_ToggleButton, {
        class: "background-dynamic-lighter-gray",
        onClick: _ctx.onItalic,
        onMousedown: _ctx.preventFocus,
        modelValue: _ctx.italic,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.italic) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, { name: "fas fa-italic" })
        ]),
        _: 1
      }, 8, ["onClick", "onMousedown", "modelValue"]),
      _createVNode(_component_ToggleButton, {
        class: "background-dynamic-lighter-gray",
        onClick: _ctx.onUnderline,
        onMousedown: _ctx.preventFocus,
        modelValue: _ctx.underline,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.underline) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, { name: "fas fa-underline" })
        ]),
        _: 1
      }, 8, ["onClick", "onMousedown", "modelValue"])
    ]),
    _createElementVNode("div", {
      ref: "editor",
      class: "editor-content",
      contenteditable: "true",
      innerHTML: _ctx.content,
      onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInput($event.target.innerHTML, $event.target.innerText))),
      onKeyup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onCaretMove && _ctx.onCaretMove(...args))),
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onCaretMove && _ctx.onCaretMove(...args)))
    }, null, 40, _hoisted_3)
  ]))
}