import {
    ClientCriteria,
    ClientDevisReadData,
    ClientDevisReadResource,
    ClientFichierReadData,
    ClientReadData,
    ClientReadResource,
    ClientWriteData,
    ClientWriteResource,
    CollaborateurTableClient,
    GerantAssocieClientCriteria,
    GerantAssocieTableClient,
    IndependantTableClient,
    VacataireTableClient
} from '@/model/dossier/client'
import {LabelData, PaginationCriteria, SearchResult} from '@/model/common'
import {buildSortParam, del, get, getFileOrNull, getPaginated, post, put, putMultipart} from '@/services/http.service'
import {perimetre} from '@/services/authentication.service'
import {ClientPartageReadData, ClientPartagesUpdateData} from '@/model/partage'
import {TableClientHistorique} from '@/model/historique'
import {AssocieDevisEmetteurType, ClientDevisCreateResource} from '@/model/devis'

export function findLabelClients(): Promise<LabelData[]> {
    return get.value('/api/client')
}

export function findLabelClientsByExistsClientFacture(): Promise<LabelData[]> {
    return get.value('/api/client/_existsFacture')
}

export function searchOwnGerantAssocieClients(criteria: GerantAssocieClientCriteria): Promise<SearchResult<GerantAssocieTableClient>> {
    if (perimetre.value?.type !== 'GERANCE' && perimetre.value?.type !== 'ASSOCIATION') throw new Error('Not allowed')
    return getPaginated.value('/api/client/_search', buildParamsFromGerantAssocieClientCriteria(criteria))
}

export function searchOwnCollaborateurClients(criteria: ClientCriteria): Promise<SearchResult<CollaborateurTableClient>> {
    if (perimetre.value?.type !== 'COLLABORATION') throw new Error('Not allowed')
    return getPaginated.value('/api/client/_search', buildParamsFromClientCriteria(criteria))
}

export function searchOwnVacataireClients(criteria: ClientCriteria): Promise<SearchResult<VacataireTableClient>> {
    if (perimetre.value?.type !== 'VACATARIAT') throw new Error('Not allowed')
    return getPaginated.value('/api/client/_search', buildParamsFromClientCriteria(criteria))
}

export function searchOwnIndependantClients(criteria: ClientCriteria): Promise<SearchResult<IndependantTableClient>> {
    if (perimetre.value?.type !== 'INDEPENDANT') throw new Error('Not allowed')
    return getPaginated.value('/api/client/_search', buildParamsFromClientCriteria(criteria))
}

export async function getClientById(id: string): Promise<ClientReadData> {
    const resource = await get.value<ClientReadResource>(`/api/client/${id}`)
    const fichiers = resource.fichiers
    const files = await Promise.all(fichiers.map(({ id }) => {
        return getClientDossierMedicalFichierById(id);
    }))
    const fichiersWithFiles = fichiers
        .map((fichier, idx) => ({ ...fichier, file: files[idx] }))
        .filter(({ file }) => file != null)
    return {
        ...resource,
        fichiers: fichiersWithFiles
            .map((fichier, idx) => ({ ...fichier, file: files[idx] }))
            .filter(({ file }) => file != null) as ClientFichierReadData[]
    }
}


export async function getLabelClientById(id: string): Promise<LabelData> {
    return await get.value<LabelData>(`/api/client/${id}`, {},{'Representation': 'Label'})
}

function getClientDossierMedicalFichierById(id: string): Promise<File|null> {
    return getFileOrNull.value(`/api/client/fichier/${id}`)
}

export function updateClientById(id: string, data: ClientWriteData): Promise<void> {
    const fichiers = data.fichiers.map(({file}) => file)
    const resource: ClientWriteResource = buildClientWriteResourceFromClientWriteData(data)
    const formData = new FormData()

    formData.append("resource", JSON.stringify(resource))
    fichiers.forEach((fichier, idx) => formData.append(`fichier_${idx}`, fichier))

    return putMultipart.value(`/api/client/${id}`, formData)
}

export function findPartageByClientId(id: string): Promise<ClientPartageReadData[]> {
    return get.value(`/api/client/${id}/partage`)
}

export function updatePartagesByClientId(id: string, data: ClientPartagesUpdateData): Promise<void> {
    return put.value(`/api/client/${id}/partage`, data)
}

export function deleteClientById(id: string): Promise<void> {
    return del.value(`/api/client/${id}`)
}

export function searchPaginatedTableHistoriquesByClientId(id: string, pagination: PaginationCriteria): Promise<SearchResult<TableClientHistorique>> {
    return getPaginated.value(`/api/client/${id}/historique`, buildClientHistoriquePagination(pagination))
}

export async function findClientDevisListByClientId(id: string): Promise<ClientDevisReadData[]> {
    const devisList = await get.value<ClientDevisReadResource[]>(`/api/client/${id}/devis`)
    const files = await Promise.all(devisList.map(({ id: devisId }) => getClientDevisByPatientIdAndId(id, devisId)))
    const devisWithFiles = devisList
        .map((devis, idx) => ({ ...devis, file: files[idx] }))
    return devisWithFiles
        .map((devis, idx) => ({ ...devis, file: files[idx] }))
        .filter(({ file }) => file != null) as ClientDevisReadData[]
}

function getClientDevisByPatientIdAndId(id: string, devisId: string): Promise<File|null> {
    return getFileOrNull.value(`/api/client/${id}/devis/${devisId}`)
}

export function deleteClientDevisByClientIdAndId(id: string, devisId: string): Promise<void> {
    return del.value(`/api/client/${id}/devis/${devisId}`)
}

export function generateDevisClientById(id: string, resource: ClientDevisCreateResource): Promise<void> {
    return post.value(`/api/client/${id}/_generateDevis`, resource)
}

export function generateAssocieDevisClientById(id: string, resource: ClientDevisCreateResource, emetteur: AssocieDevisEmetteurType): Promise<void> {
    return post.value(`/api/client/${id}/_generateDevis`, resource, {'Emetteur': emetteur})
}

function buildParamsFromClientCriteria(criteria: ClientCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if(criteria.filters.nomRaisonSociale) params.nomRaisonSociale = criteria.filters.nomRaisonSociale
    if(criteria.filters.prenom) params.prenom = criteria.filters.prenom
    if(criteria.filters.codePostal) params.codePostal = criteria.filters.codePostal
    if(criteria.filters.statut) params.statut = criteria.filters.statut
    if(criteria.sort) params.sort = buildSortParam(criteria.sort)
    return params
}

function buildParamsFromGerantAssocieClientCriteria(criteria: GerantAssocieClientCriteria) {
    const params = buildParamsFromClientCriteria(criteria)
    if (criteria.filters.utilisateurIdOrNone) params.utilisateurIdOrNone = criteria.filters.utilisateurIdOrNone
    return params
}


export const buildClientWriteResourceFromClientWriteData = (data: ClientWriteData): ClientWriteResource => ({
    nomRaisonSociale: data.nomRaisonSociale,
    prenom: data.prenom,
    statut: data.statut,
    telephones: data.telephones,
    contacts: data.contacts,
    demande: data.demande,
    reference: data.reference,
    email: data.email,
    fichiers: data.fichiers.map(fichier => ({
        type: fichier.type,
        nom: fichier.nom
    })),
    lieuPriseEnCharges: data.lieuPriseEnCharges,
    tarifDefaut: data.tarifDefaut,
    siret: data.siret,
    adresse: data.adresse,
    codeApe: data.codeApe
})


function buildClientHistoriquePagination(pagination: PaginationCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: pagination.number.toString(),
        pageSize: pagination.size.toString()
    }
    return params
}