import {RouteRecordRaw} from "vue-router";
import Agenda from "@/views/admin/agenda/Agenda.vue"

const routes = [{
    path: '/agenda',
    name: 'Agenda',
    component: Agenda
}] as Array<RouteRecordRaw>;

export default routes;
