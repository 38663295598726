import {del, get, post, putMultipart} from "@/services/http.service";
import {
    CollaborationCreateResource,
    CollaborationReadResource,
    CollaborationUpdateResource
} from "@/model/collaboration";
import {UtilisateurTableCollaboration} from "@/model/utilisateur";
import {getEntiteLogoById} from "@/services/entite.service";
import {CollaborationPermissions} from '@/model/collaboration'


export async function getCollaborationById(id: string): Promise<CollaborationReadResource> {
    const [ resource, logo ] = await Promise.all([get.value<CollaborationReadResource>(`/api/collaboration/${id}`), getEntiteLogoById(id)])
    return {
        ...resource,
        entite: {
            ...resource.entite,
            logo: logo
        }
    }
}

export function findUtilisateurCollaborationsByUtilisateurId(utilisateurId: string): Promise<UtilisateurTableCollaboration[]> {
    return get.value<UtilisateurTableCollaboration[]>('/api/collaboration', {utilisateurId})
}

export function createCollaboration(collaboration: CollaborationCreateResource): Promise<void> {
    return post.value('/api/collaboration', collaboration)
}

export function updateCollaborationById(id: string, resource: CollaborationUpdateResource): Promise<void> {
    const { entite : { logo, ...entite }, ...collaboration } = resource
    const formData = new FormData()
    formData.append("resource", JSON.stringify({
        ...collaboration,
        entite
    }))
    if(logo != null) {
        formData.append("logo", logo)
    }
    return putMultipart.value(`/api/collaboration/${id}`,formData)
}

export function deleteCollaborationById(id: string): Promise<void> {
    return del.value(`/api/collaboration/${id}`)
}

export async function getCollaborationPermissionsById(id: string): Promise<CollaborationPermissions> {
    return get.value(`/api/collaboration/${id}/permissions`)
}
