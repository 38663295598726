import {PatientWriteData, PatientWriteResource} from "@/model/dossier/patient";
import {postMultipart} from "@/services/http.service";
import {gestionnaireDossierId} from "@/services/authentication.service";
import {buildPatientWriteResourceFromPatientWriteData} from "@/services/patient.service";
import {buildClientWriteResourceFromClientWriteData} from "@/services/client.service";
import {ClientWriteData, ClientWriteResource} from "@/model/dossier/client";

export function createPatientByOwnGestionnaireDossierId(data: PatientWriteData): Promise<string> {
    const fichiers = data.dossierMedical.fichiers.toCreate.map(({file}) => file)
    const resource: PatientWriteResource = buildPatientWriteResourceFromPatientWriteData(data)
    const formData = new FormData()

    formData.append("resource", JSON.stringify(resource))
    fichiers.forEach((fichier, idx) => formData.append(`fichier_${idx}`, fichier))

    return postMultipart.value(`/api/gestionnaireDossier/${gestionnaireDossierId.value}/patient`,formData)
}

export function createClientByOwnGestionnaireDossierId(data: ClientWriteData): Promise<string> {
    const fichiers = data.fichiers.map(({file}) => file)
    const resource: ClientWriteResource = buildClientWriteResourceFromClientWriteData(data)
    const formData = new FormData()

    formData.append("resource", JSON.stringify(resource))
    fichiers.forEach((fichier, idx) => formData.append(`fichier_${idx}`, fichier))

    return postMultipart.value(`/api/gestionnaireDossier/${gestionnaireDossierId.value}/client`,formData)
}




