
import {defineComponent} from 'vue';
import Icon from "@/components/common/icon/Icon.vue";

export default defineComponent({
  name: 'Tickbox',
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  }
})
