import {Adresse, LabelData, PaginationCriteria, Sort} from '@/model/common'
import {LabelCabinet} from '@/model/cabinet'
import {AssociationCreateData, CabinetAssociationCreateResource} from '@/model/association'
import {
    CabinetCollaborationCreateData,
    CabinetCollaborationCreateResource,
    CabinetVacationCreateData
} from '@/model/collaboration'
import {Individu} from '@/model/individu'
import {EntiteUpdateData} from '@/model/entite'

export interface Utilisateur {
    id: string | null;
    emailId: string,
    telephoneId: string,
}

export interface CabinetLabelUtilisateur {
    id: string,
    label: string,
    role: CabinetUtilisateurRole
}

export interface CabinetLabelCollaborateur {
    id: string,
    label: string,
    tauxRedevance: number
}

export interface EvenementLabelUtilisateur {
    id: string,
    label: string,
    role: CabinetUtilisateurRole | null
    depassementFranchiseTva: boolean,
    patients: RdvEvenementUtilisateurPatientReadData[],
    clients: LabelData[],
}

export interface RdvEvenementUtilisateurPatientReadData {
    id: string,
    label: string,
    remplace: string | null
}

export interface UtilisateurUpdateResource {
    individu: Individu,
    email: string,
    telephone: string,
    actif: boolean,
    superAdmin: boolean,
    numeroRpps: string | null,
    profession: string | null
}

export interface UtilisateurUpdateData {
    individu: Individu,
    email: string,
    telephone: string,
    actif: boolean,
    superAdmin: boolean,
    numeroRpps: string | null,
    profession: string | null
}

export interface SelfUtilisateurUpdateData {
    individu: Individu,
    email: string,
    telephone: string,
    password: string | null,
    numeroRpps: string | null,
    profession: string | null,
    entite: EntiteUpdateData | null,
    acteMentions: string[],
    factureDevisMentions: string[],
    adresse: Adresse | null
}

export interface SelfUtilisateurUpdateResource {
    individu: Individu,
    email: string,
    telephone: string,
    numeroRpps: string | null,
    profession: string | null
    password: string | null,
    acteMentions: string[],
    factureDevisMentions: string[],
    adresse: Adresse | null
}

export interface UtilisateurCreateData {
    individu: Individu,
    email: string,
    telephone: string,
    actif: boolean,
    password: string | null,
    superAdmin: boolean
}

export interface UtilisateurCreateResource {
    individu: Individu,
    email: string,
    telephone: string,
    actif: boolean,
    password: string | null,
    superAdmin: boolean
}

export interface SelfUtilisateurReadData {
    id: string,
    individu: Individu,
    email: string,
    telephone: string,
    numeroRpps: string | null,
    profession: string | null,
    acteMentions: string[],
    factureDevisMentions: string[],
    adresse: Adresse | null
}

export interface SelfUtilisateurReadResource {
    id: string,
    individu: Individu,
    email: string,
    telephone: string,
    numeroRpps: string | null,
    profession: string | null,
    acteMentions: string[],
    factureDevisMentions: string[],
    adresse: Adresse | null,
}

export interface UtilisateurReadData {
    id: string,
    individu: Individu,
    email: string,
    telephone: string,
    numeroRpps: string | null,
    profession: string | null,
    actif: boolean,
    superAdmin: boolean
}

export interface UtilisateurReadResource {
    id: string,
    individu: Individu,
    email: string,
    telephone: string,
    numeroRpps: string | null,
    profession: string | null,
    actif: boolean,
    superAdmin: boolean
}

export interface UtilisateurTableGerance {
    id: string,
    cabinetId: string,
}

export interface UtilisateurTableAssociation {
    id: string,
    siret: string,
    cabinetId: string,
    duplicationPatient: boolean,
    facturation: boolean,
    parametrageCabinet: boolean
}

export interface UtilisateurTableCollaboration {
    id: string,
    siret: string,
    cabinetId: string,
    tauxRedevance: boolean
}

export interface UtilisateurTableVacatariat {
    id: string,
    siret: string,
    cabinetId: string
}

export interface AssocieCreateResource {
    utilisateur: UtilisateurCreateResource,
    association: CabinetAssociationCreateResource
}

export interface AssocieCreateData {
    utilisateur: UtilisateurCreateData,
    association: AssociationCreateData
}

export interface CollaborateurCreateResource {
    utilisateur: UtilisateurCreateResource,
    collaboration: CabinetCollaborationCreateResource
}

export interface CollaborateurCreateData {
    utilisateur: UtilisateurCreateData,
    collaboration: CabinetCollaborationCreateData
}

export interface VacataireCreateData {
    utilisateur: UtilisateurCreateData,
    vacatariat: CabinetVacationCreateData
}

export interface SuperAdminTableUtilisateur {
    id: string,
    nom: string,
    prenom: string | null,
    email: string,
    geranceCabinets: LabelCabinet[],
    associationCabinets: LabelCabinet[],
    collaborationCabinets: LabelCabinet[],
    vacatariatCabinets: LabelCabinet[],
    independant: boolean,
    superAdmin: boolean,
    actif: boolean,
    dateDerniereConnexion: string | null
}

export interface GerantAssocieTableUtilisateur {
    id: string,
    nom: string,
    siret: string | null,
    prenom: string | null,
    email: string,
    relationId: string,
    role: CabinetUtilisateurRole,
    actif: boolean,
    superAdmin: boolean,
    dateDerniereConnexion: string | null
}

export type CabinetUtilisateurRole = 'GERANT' | 'ASSOCIE' | 'COLLABORATEUR' | 'VACATAIRE'
export const cabinetUtilisateurRoleLabelMap: Record<CabinetUtilisateurRole, string> = {
    GERANT: 'Gérant',
    ASSOCIE: 'Associé',
    COLLABORATEUR: 'Collaborateur',
    VACATAIRE: 'Vacataire',
}

export interface UtilisateurCriteria {
    pagination: PaginationCriteria,
    filters: UtilisateurCriteriaFilters,
    sort: Sort | null
}

export interface UtilisateurCriteriaFilters {
    nom: string | null
    prenom: string | null
    actif: boolean | null
}

export interface SelectUtilisateur {
    id: string,
    nom: string,
    prenom: string,
}

export interface RdvEvenementCabinetUtilisateurReadData {
    role: CabinetUtilisateurRole,
    depassementFranchiseTva: boolean,
    patients: RdvEvenementUtilisateurPatientReadData[],
    clients: LabelData[],
}

export interface RdvEvenementUtilisateurReadData {
    depassementFranchiseTva: boolean,
    patients: RdvEvenementUtilisateurPatientReadData[],
    clients: LabelData[],
}
