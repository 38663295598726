import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, {
    "auto-columns": "1fr",
    "auto-flow": "column",
    class: "button-group"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }))
}