import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "selected", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("option", {
    value: _ctx.val,
    selected: _ctx.selected,
    disabled: _ctx.disabled
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}