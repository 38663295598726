
import {defineComponent, ref} from 'vue';
import Button from "@/components/common/button/Button.vue";
export default defineComponent({
  name: 'ToggleButton',
  components: {
    Button,
  },
  props: {
    disabled: Boolean,
    modelValue: {
      type: Boolean,
      required: false,
      default: null
    },
    value: {
      type: Boolean,
      required: false
    }
  },
  setup(props, ctx) {
    const button = ref<HTMLButtonElement>();

    const onToggle = () => {
      if (props.modelValue === null) {
        ctx.emit('update:modelValue', true);
      } else {
        ctx.emit('update:modelValue', !props.modelValue);
      }
    }

    return {
      button,
      onToggle
    }
  }
})

