import {
    CabinetCreateResource,
    CabinetCriteria,
    CabinetInfosPriseRdv,
    CabinetReadData,
    CabinetUpdateResource,
    LabelCabinet,
    SuperAdminTableCabinet,
    TableCabinet
} from "@/model/cabinet";
import {LabelData, SearchResult} from "@/model/common";
import {
    AssocieCreateResource,
    CollaborateurCreateResource,
    UtilisateurCreateResource,
    VacataireCreateData
} from '@/model/utilisateur'
import {cabinetId} from "@/services/authentication.service";
import {getEntiteLogoById} from "@/services/entite.service";
import {buildSortParam, del, get, getOrNull, getPaginated, post, putMultipart} from "@/services/http.service";

export function findAllLabelCabinets(): Promise<LabelData[]> {
    return get.value<LabelData[]>(`/api/cabinet`);
}

export function searchSuperAdminTableCabinets(criteria: CabinetCriteria): Promise<SearchResult<SuperAdminTableCabinet>> {
    return getPaginated.value<SuperAdminTableCabinet>('/api/cabinet/_search', buildParamsFromCabinetCriteria(criteria))
}

export function searchCabinets(criteria: CabinetCriteria): Promise<SearchResult<TableCabinet>> {
    return getPaginated.value<TableCabinet>('/api/cabinet/_search', buildParamsFromCabinetCriteria(criteria))
}

export async function getCabinetById(id: string): Promise<CabinetReadData> {
    const [ resource, logo ] = await Promise.all([get.value<CabinetReadData>(`/api/cabinet/${id}`), getEntiteLogoById(id)])
    return {
        ...resource,
        entite: {
            ...resource.entite,
            logo: logo
        }
    }
}

export function getCabinetInfosPriseRdvById(id: string): Promise<CabinetInfosPriseRdv> {
    return get.value<CabinetInfosPriseRdv>(`/api/cabinet/${id}/infosPriseRdv`);
}

export function getCabinetLogoByCabinetIdAndLogoFilename(cabinetId: string, filename: string): Promise<File|null> {
    return getOrNull.value(`/api/cabinet/${cabinetId}/logo/${filename}`);
}

export function findSelectCabinetsByUtilisateurId(utilisateurId: string): Promise<LabelCabinet[]> {
    return get.value<LabelCabinet[]>(`/api/utilisateur/${utilisateurId}/cabinet`);
}

export function createCabinet(cabinet: CabinetCreateResource): Promise<void> {
    return post.value('/api/cabinet', cabinet)
}

export function updateCabinetById(id: string, resource: CabinetUpdateResource): Promise<void> {
    const { entite : { logo, ...entite }, ...cabinet } = resource

    const formData = new FormData()
    formData.append("resource", JSON.stringify({
        ...cabinet,
        entite
    }))
    if (logo != null) {
        formData.append("logo", logo)
    }

    return putMultipart.value(`/api/cabinet/${id}`, formData)
}

export function deleteCabinetById(id: string): Promise<void> {
    return del.value(`/api/cabinet/${id}`)
}

export function createOwnCabinetGerant(utilisateur: UtilisateurCreateResource): Promise<void> {
    return post.value(`/api/cabinet/${cabinetId.value}/gerant`, utilisateur)
}

export function createOwnCabinetAssocie(associe: AssocieCreateResource): Promise<void> {
    return post.value(`/api/cabinet/${cabinetId.value}/associe`, associe)
}

export function createOwnCabinetCollaborateur(collaborateur: CollaborateurCreateResource): Promise<void> {
    return post.value(`/api/cabinet/${cabinetId.value}/collaborateur`, collaborateur)
}

export function createOwnCabinetVacataire(vacataire: VacataireCreateData): Promise<void> {
    return post.value(`/api/cabinet/${cabinetId.value}/vacataire`, vacataire)
}

function buildParamsFromCabinetCriteria(criteria: CabinetCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if(criteria.filters.raisonSociale) params.raisonSociale = criteria.filters.raisonSociale
    if(criteria.sort) params.sort = buildSortParam(criteria.sort)
    return params
}
