export interface Credentials {
    email: string;
    password: string;
}

export interface Authentication {
    id: string,
    nom: string,
    prenom: string,
    perimetres: AuthenticationPerimetres
}

export interface AuthenticationPerimetres {
    superAdmin: boolean,
    independantPerimetre: IndependantPerimetre | null,
    gerancePerimetres: GerancePerimetre[],
    collaborationPerimetres: CollaborationPerimetre[],
    vacatariatPerimetres: VacatariatPerimetre[],
    associationPerimetres: AssociationPerimetre[]
}

export interface IndependantPerimetre {
    etablissementId: string
}

export interface GerancePerimetre {
    cabinet: AuthenticationCabinet,
    geranceId: string,
    etablissementId: string
}

export interface AssociationPerimetre {
    cabinet: AuthenticationCabinet,
    associationId: string,
    duplicationPatient: boolean,
    etablissementId: string
}

export interface CollaborationPerimetre {
    cabinet: AuthenticationCabinet
    collaborationId: string,
    etablissementId: string
}

export interface VacatariatPerimetre {
    cabinet: AuthenticationCabinet
    vacatariatId: string,
    etablissementId: string
}

export interface AuthenticationCabinet {
    id: string
    etablissementId: string
    raisonSociale: string
}


export type PerimetreType = 'SUPER_ADMIN' | 'GERANCE' | 'ASSOCIATION' | 'COLLABORATION' | 'VACATARIAT' | 'INDEPENDANT'
export const perimetreTypeLabelMap: Record<PerimetreType, string> = {
    SUPER_ADMIN: 'Super admin',
    GERANCE: 'Gérance',
    ASSOCIATION: 'Association',
    COLLABORATION: 'Collaboration',
    VACATARIAT: 'Vacatariat',
    INDEPENDANT: 'Indépendant'
}

export interface LabelPerimetre {
    id: string
    type: PerimetreType
}

export interface SuperAdminLabelPerimetre extends LabelPerimetre {
    type: 'SUPER_ADMIN'
}

export interface IndependantLabelPerimetre extends LabelPerimetre {
    type: 'INDEPENDANT',
    etablissementId: string
}

export interface GeranceLabelPerimetre extends LabelPerimetre {
    type: 'GERANCE',
    cabinet: AuthenticationCabinet,
    geranceId: string,
    etablissementId: string
}
export interface AssociationLabelPerimetre extends LabelPerimetre {
    type: 'ASSOCIATION',
    cabinet: AuthenticationCabinet,
    associationId: string,
    duplicationPatient: boolean,
    etablissementId: string

}

export interface CollaborationLabelPerimetre extends LabelPerimetre {
    type: 'COLLABORATION',
    cabinet: AuthenticationCabinet,
    collaborationId: string,
    etablissementId: string
}

export interface VacatariatLabelPerimetre extends LabelPerimetre {
    type: 'VACATARIAT',
    cabinet: AuthenticationCabinet,
    vacatariatId: string,
    etablissementId: string
}

export type PerimetreUtilisateur = SuperAdminLabelPerimetre | GeranceLabelPerimetre | AssociationLabelPerimetre | CollaborationLabelPerimetre | VacatariatLabelPerimetre | IndependantLabelPerimetre


