export type ReglementMode = 'CB' |  'VIREMENT' | 'CHEQUE' | 'ESPECES' | 'AVOIR'
export const reglementModeLabelMap: Record<ReglementMode, string> = {
    CB: 'Carte bancaire',
    CHEQUE: 'Chèque',
    ESPECES: 'Espèces',
    VIREMENT: 'Virement',
    AVOIR: 'Avoir'
}


export interface Reglement {
    montant: number,
    mode: ReglementMode,
    date: string,
    description: string | null
}