import {
    AssocieFactureCreateResource, AssocieFactureCriteria,
    FactureAvoirFactureReadData,
    FactureCreateResource,
    FactureCriteria,
    FactureGenerateModel,
    FactureLibreCreateData,
    FactureReglement,
    FactureSendReadData,
    FactureSendWriteData,
    FactureUpdateData,
    TableFacture
} from '@/model/facture'
import {del, get, getPaginated, post, put} from '@/services/http.service'
import {DataResource, SearchResult} from '@/model/common'
import {AvoirFactureCreateData} from '@/model/avoir'

export function getFactureGenerateModelById(id: string): Promise<FactureGenerateModel> {
    return get.value(`/api/facture/${id}/model`)
}

export function searchTablePaginatedFactures(criteria: FactureCriteria): Promise<SearchResult<TableFacture>> {
    return getPaginated.value('/api/facture/_search', buildParamsFromFactureCriteria(criteria))
}

export function searchAssocieTablePaginatedFactures(criteria: AssocieFactureCriteria): Promise<SearchResult<TableFacture>> {
    return getPaginated.value('/api/facture/_search', buildParamsFromAssocieFactureCriteria(criteria))
}

export function createFactures(resource: FactureCreateResource): Promise<void> {
    return post.value(`/api/facture`, resource)
}

export function createAssocieFactures(resource: AssocieFactureCreateResource): Promise<void> {
    return post.value(`/api/facture`, resource)
}

export function updateFactureById(id: string, data: FactureUpdateData): Promise<void> {
    return put.value(`/api/facture/${id}`, data)
}

export function updateFactureReglementsByFactureId(factureId: string, reglements: FactureReglement[]): Promise<void> {
    return put.value(`/api/facture/${factureId}/reglement`, { reglements })
}

export function updateFactureReglementsAndGenerateFactureByFactureId(factureId: string, reglements: FactureReglement[]): Promise<void> {
    return post.value(`/api/facture/${factureId}/_updateReglementsAndGenerate`, { reglements })
}

export function updateFactureLibreReglementsAndGenerateFactureByFactureId(factureId: string, reglements: FactureReglement[]): Promise<void> {
    return post.value(`/api/facture-libre/${factureId}/_updateReglementsAndGenerate`, { reglements })
}

export function findFactureReglementsByFactureId(factureId: string): Promise<FactureReglement[]> {
    return get.value(`/api/facture/${factureId}/reglement`)
}

export function findFactureLibreReglementsByFactureId(factureId: string): Promise<FactureReglement[]> {
    return get.value(`/api/facture-libre/${factureId}/reglement`)
}

export function generateFactureById(id: string): Promise<void> {
    return post.value(`/api/facture/${id}/_generate`)
}

export function createFactureLibre(data: FactureLibreCreateData): Promise<void> {
    return post.value(`/api/facture-libre`, data)
}

export function updateFactureLibreReglementsAndGenerateFactureByFactureLibreId(factureId: string, reglements: FactureReglement[]): Promise<void> {
    return post.value(`/api/facture-libre/${factureId}/_updateReglementsAndGenerate`, { reglements })
}

export function deleteFactureById(id: string): Promise<void> {
    return del.value(`/api/facture/${id}`)
}

export function getFactureSendDataById(id: string): Promise<FactureSendReadData> {
    return get.value(`/api/facture/${id}/sendData`)
}

export function sendFactureById(id: string, data: FactureSendWriteData) {
    return post.value(`/api/facture/${id}/_send`, data)
}

export function getFactureLatestDate(): Promise<string|null> {
    return get.value<DataResource<string|null>>('/api/facture/_latestFactureDate')
        .then(({ data }) => data)
}

export function getAssocieFactureLatestDate(own: boolean): Promise<string|null> {
    return get.value<DataResource<string|null>>('/api/facture/_latestFactureDate', { prestataire: own ? 'ASSOCIE' : 'CABINET'})
        .then(({ data }) => data)
}

export function getFactureNumeroByAnneeAndNumero(etablissementId: string, annee: number, numero: number): Promise<string|null> {
    return get.value<DataResource<string|null>>('/api/facture/_numero', {
        annee: annee.toString(),
        numero: numero.toString(),
        etablissementId: etablissementId
    }).then(({ data }) => data)
}

export function getFactureByAnneeAndNextNumero(annee: number): Promise<string|null> {
    return get.value<DataResource<string|null>>('/api/facture/_numero', {
        annee: annee.toString(),
    }).then(({ data }) => data)
}

export function createAvoirFactureByFactureId(id: string, data: AvoirFactureCreateData): Promise<void> {
    return post.value(`/api/facture/${id}/avoir-facture`, data, {'Representation': 'Facture'})
}

export function createAvoirFactureByFactureLibreId(id: string, data: AvoirFactureCreateData): Promise<void> {
    return post.value(`/api/facture/${id}/avoir-facture`, data, {'Representation': 'Facture-Libre'})
}

export function getFactureAvoirFactureById(id: string): Promise<FactureAvoirFactureReadData> {
    return get.value(`/api/facture/${id}`, {}, {'Representation': 'Avoir-Facture'})
}

export function getFactureLibreAvoirFactureById(id: string): Promise<FactureAvoirFactureReadData> {
    return get.value(`/api/facture-libre/${id}`, {}, {'Representation': 'Avoir-Facture'})
}

function buildParamsFromFactureCriteria(criteria: FactureCriteria) {
    const params: {[key: string]: string} = {
        pageNumber: criteria.pagination.number.toString(),
        pageSize: criteria.pagination.size.toString()
    }
    if (criteria.filters.statut) params.statut = criteria.filters.statut
    if (criteria.filters.debutAfter) params.debutAfter = criteria.filters.debutAfter
    if (criteria.filters.finBefore) params.finBefore = criteria.filters.finBefore
    if (criteria.filters.destinataire) params.destinataire = criteria.filters.destinataire
    if (criteria.filters.numero) params.numero = criteria.filters.numero
    if (criteria.filters.soldee != null) params.soldee = criteria.filters.soldee.toString()
    return params
}

function buildParamsFromAssocieFactureCriteria(criteria: AssocieFactureCriteria) {
    const params = buildParamsFromFactureCriteria(criteria)
    params.prestataire = criteria.filters.prestataire
    return params
}