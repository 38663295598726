import {NavigationGuard} from "vue-router";
import {HttpError} from "@/services/http.service";

export const handleBeforeEnterHttpErrors = (fn: NavigationGuard): NavigationGuard => {
    return (async (to, from, next) => {
        try {
            await fn(to, from, next)
        } catch(e) {
            console.error(e)
            if (e instanceof HttpError) {
                if(e.status === 404) {
                    return next('/donnee-inexistante')
                }
                if(e.status === 401) {
                    return next('/acces-interdit')
                }
            }
            throw e
        }
    })
}